import Cv from "@/models/cv";
import CvoBasic from "@/models/cvo-basic";
import CvoAdv from "@/models/cvo-adv";
import CvoIntent from "@/models/cvo-intent";
import CvoWorkExp from "@/models/cvo-work-exp";
import CvoProjectExp from "@/models/cvo-project-exp";
import CvoSocial from "@/models/cvo-social";
import CvoEdu from "@/models/cvo-edu";
import CvAttachment from "@/models/cv-attachment";
import moment from "moment";

export default class Cvo {

    constructor(options) {
        this.initCv(options)
        this.initBasic(options)
        this.initAdv(options)
        this.initIntents(options)
        this.initWorkExps(options)
        this.initProjectExps(options)
        this.initSocials(options)
        this.initEducations(options)
        this.initAttachments(options)
    }

    initCv(options) {
        this.cv = new Cv(options)
        this.isNameEditing = this.cv.isNew
    }

    initBasic(options) {
        this.basic = new CvoBasic(options?.basic)
        this.isBasicEditing = false
    }

    initAdv(options) {
        this.advantage = new CvoAdv(options?.advantage)
        this.isAdvEditing = false
    }

    initIntents(options) {
        this.intents = []
        let its = options?.intents
        if (its && its.length > 0) {
            for (let i = 0; i < its.length; i++) {
                let it = its[i]
                this.intents.push(new CvoIntent(it))
            }
        }
    }

    initWorkExps(options) {
        this.workExps = []
        let exps = options?.workExps
        if (exps && exps.length > 0) {
            for (let i = 0; i < exps.length; i++) {
                let exp = exps[i]
                this.workExps.push(new CvoWorkExp(exp))
            }
            this.workExps.sort(function (a,b) {
                return moment(a.from).isBefore(moment(b.from))
            })
        }
    }

    initProjectExps(options) {
        this.projectExps = []
        let exps = options?.projectExps
        if (exps && exps.length > 0) {
            for (let i = 0; i < exps.length; i++) {
                let exp = exps[i]
                this.projectExps.push(new CvoProjectExp(exp))
            }
            this.projectExps.sort(function (a,b) {
                return moment(a.from).isBefore(moment(b.from))
            })
        }
    }

    initSocials(options) {
        this.socials = []
        let ss = options?.socials
        if (ss && ss.length > 0) {
            for (let i = 0; i < ss.length; i++) {
                let s = ss[i]
                this.socials.push(new CvoSocial(s))
            }
        }
    }

    initEducations(options) {
        this.educations = []
        let educations = options?.educations
        if (educations && educations.length > 0) {
            for (let i = 0; i < educations.length; i++) {
                let edu = educations[i]
                this.educations.push(new CvoEdu(edu))
            }
            this.educations.sort(function (a,b) {
                return moment(a.from).isBefore(moment(b.from))
            })
        }
    }

    initAttachments(options) {
        this.isAttachEditing = false
        this.attachments = []
        let attachments = options?.attachments
        if (attachments && attachments.length > 0) {
            for (let i = 0; i < attachments.length; i++) {
                let at = attachments[i]
                this.attachments.push(new CvAttachment(at))
            }
        }
    }

    get isNew() {
        return this.cv?._id <=0
    }

    get hasFilledCv() {
        return this.cv?.name
    }

    get hasFilledBasic() {
        return this.basic?.userName
    }

    get hasFilledAdvantage() {
        return this.advantage?.advantage
    }

    get hasIntents() {
        return this.intents && this.intents.length > 0
    }

    get hasWorkExps() {
        return this.workExps && this.workExps.length > 0
    }

    get hasProjectExps() {
        return this.projectExps && this.projectExps.length > 0
    }

    get hasEducations() {
        return this.educations && this.educations.length > 0
    }

    get hasSocials() {
        return this.socials && this.socials.length > 0
    }

    get hasAttachments() {
        return this.attachments && this.attachments.length > 0
    }

    get completeRate() {
        let rate = 0
        if (this.hasFilledCv) {
            rate += 4
        }
        if (this.hasFilledBasic) {
            rate += 16
        }
        if (this.hasFilledAdvantage) {
            rate += 16
        }
        if (this.hasIntents) {
            rate += 16
        }
        if (this.hasWorkExps) {
            rate += 16
        }
        if (this.hasProjectExps) {
            rate += 16
        }
        if (this.hasEducations) {
            rate += 16
        }
        return rate
    }

    clone() {
        let cvo = new Cvo()
        cvo.cv = this.cv?.clone()
        cvo.isNameEditing = this.isNameEditing

        cvo.basic = this.basic?.clone()
        cvo.isBasicEditing = this.isBasicEditing

        cvo.advantage = this.advantage?.clone()
        cvo.isAdvEditing = this.isAdvEditing

        cvo.intents = [...this.intents]
        cvo.workExps = [...this.workExps]
        cvo.projectExps = [...this.projectExps]
        cvo.educations = [...this.educations]
        cvo.socials = [...this.socials]

        cvo.attachments = [...this.attachments]
        cvo.isAttachEditing = this.isAttachEditing
        return cvo
    }

    toJSON () {
        return { ...this }
    }
}
