import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _704310b5 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _396ac476 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5388d622 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _74a2e41d = () => interopDefault(import('../pages/admins/audit-company.vue' /* webpackChunkName: "pages/admins/audit-company" */))
const _ae69fb6c = () => interopDefault(import('../pages/admins/audit-jd.vue' /* webpackChunkName: "pages/admins/audit-jd" */))
const _3f52a06e = () => interopDefault(import('../pages/admins/audit-order.vue' /* webpackChunkName: "pages/admins/audit-order" */))
const _0f4ec47a = () => interopDefault(import('../pages/admins/audit-real-name.vue' /* webpackChunkName: "pages/admins/audit-real-name" */))
const _dd191180 = () => interopDefault(import('../pages/admins/audit-referee.vue' /* webpackChunkName: "pages/admins/audit-referee" */))
const _e07f9b4a = () => interopDefault(import('../pages/admins/audit-user.vue' /* webpackChunkName: "pages/admins/audit-user" */))
const _2e491e1a = () => interopDefault(import('../pages/admins/audit-withdraw.vue' /* webpackChunkName: "pages/admins/audit-withdraw" */))
const _c5d9eb94 = () => interopDefault(import('../pages/admins/chat.vue' /* webpackChunkName: "pages/admins/chat" */))
const _6f4be55e = () => interopDefault(import('../pages/admins/op-carousel.vue' /* webpackChunkName: "pages/admins/op-carousel" */))
const _831b3a46 = () => interopDefault(import('../pages/admins/op-home-hot.vue' /* webpackChunkName: "pages/admins/op-home-hot" */))
const _e24d2db2 = () => interopDefault(import('../pages/admins/op-home-latest.vue' /* webpackChunkName: "pages/admins/op-home-latest" */))
const _d795e182 = () => interopDefault(import('../pages/admins/op-hot.vue' /* webpackChunkName: "pages/admins/op-hot" */))
const _7e8547f2 = () => interopDefault(import('../pages/admins/op-jd-time.vue' /* webpackChunkName: "pages/admins/op-jd-time" */))
const _5af67188 = () => interopDefault(import('../pages/admins/op-sensitive.vue' /* webpackChunkName: "pages/admins/op-sensitive" */))
const _7696ed82 = () => interopDefault(import('../pages/admins/sub-company.vue' /* webpackChunkName: "pages/admins/sub-company" */))
const _dc542476 = () => interopDefault(import('../pages/admins/sub-jd.vue' /* webpackChunkName: "pages/admins/sub-jd" */))
const _61be2b16 = () => interopDefault(import('../pages/admins/sub-user.vue' /* webpackChunkName: "pages/admins/sub-user" */))
const _1de50ee5 = () => interopDefault(import('../pages/profiles/account.vue' /* webpackChunkName: "pages/profiles/account" */))
const _39268fa0 = () => interopDefault(import('../pages/profiles/auth.vue' /* webpackChunkName: "pages/profiles/auth" */))
const _672bf7d8 = () => interopDefault(import('../pages/profiles/balance.vue' /* webpackChunkName: "pages/profiles/balance" */))
const _0893eeb9 = () => interopDefault(import('../pages/profiles/bank-card.vue' /* webpackChunkName: "pages/profiles/bank-card" */))
const _3974ed50 = () => interopDefault(import('../pages/profiles/chat.vue' /* webpackChunkName: "pages/profiles/chat" */))
const _6811cd96 = () => interopDefault(import('../pages/profiles/company.vue' /* webpackChunkName: "pages/profiles/company" */))
const _ecd580e0 = () => interopDefault(import('../pages/profiles/expense.vue' /* webpackChunkName: "pages/profiles/expense" */))
const _6d4c9938 = () => interopDefault(import('../pages/profiles/friend-fav.vue' /* webpackChunkName: "pages/profiles/friend-fav" */))
const _14f71321 = () => interopDefault(import('../pages/profiles/income.vue' /* webpackChunkName: "pages/profiles/income" */))
const _65720a00 = () => interopDefault(import('../pages/profiles/jd-fav.vue' /* webpackChunkName: "pages/profiles/jd-fav" */))
const _1e102294 = () => interopDefault(import('../pages/profiles/jd-ownership.vue' /* webpackChunkName: "pages/profiles/jd-ownership" */))
const _58f5c1f6 = () => interopDefault(import('../pages/profiles/jd-referee.vue' /* webpackChunkName: "pages/profiles/jd-referee" */))
const _417e5ac3 = () => interopDefault(import('../pages/profiles/job-fav.vue' /* webpackChunkName: "pages/profiles/job-fav" */))
const _644eed28 = () => interopDefault(import('../pages/profiles/my-cv.vue' /* webpackChunkName: "pages/profiles/my-cv" */))
const _4e66629a = () => interopDefault(import('../pages/profiles/my-jd.vue' /* webpackChunkName: "pages/profiles/my-jd" */))
const _0381c2d9 = () => interopDefault(import('../pages/profiles/my-post.vue' /* webpackChunkName: "pages/profiles/my-post" */))
const _252ac89c = () => interopDefault(import('../pages/profiles/order-receive.vue' /* webpackChunkName: "pages/profiles/order-receive" */))
const _95052ca4 = () => interopDefault(import('../pages/profiles/post-receive.vue' /* webpackChunkName: "pages/profiles/post-receive" */))
const _3b2bd8dc = () => interopDefault(import('../pages/profiles/real-name.vue' /* webpackChunkName: "pages/profiles/real-name" */))
const _2e70a750 = () => interopDefault(import('../pages/profiles/referee.vue' /* webpackChunkName: "pages/profiles/referee" */))
const _28585d20 = () => interopDefault(import('../pages/profiles/refund.vue' /* webpackChunkName: "pages/profiles/refund" */))
const _7753c76d = () => interopDefault(import('../pages/profiles/service.vue' /* webpackChunkName: "pages/profiles/service" */))
const _7d08e7fc = () => interopDefault(import('../pages/profiles/withdraw.vue' /* webpackChunkName: "pages/profiles/withdraw" */))
const _0a0cc445 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6a9e8908 = () => interopDefault(import('../pages/cvfs/_id.vue' /* webpackChunkName: "pages/cvfs/_id" */))
const _0133831f = () => interopDefault(import('../pages/cvos/_id.vue' /* webpackChunkName: "pages/cvos/_id" */))
const _78eac652 = () => interopDefault(import('../pages/jds/_id.vue' /* webpackChunkName: "pages/jds/_id" */))
const _363c2971 = () => interopDefault(import('../pages/posts/_id.vue' /* webpackChunkName: "pages/posts/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chat",
    component: _704310b5,
    name: "chat"
  }, {
    path: "/search",
    component: _396ac476,
    name: "search"
  }, {
    path: "/test",
    component: _5388d622,
    name: "test"
  }, {
    path: "/admins/audit-company",
    component: _74a2e41d,
    name: "admins-audit-company"
  }, {
    path: "/admins/audit-jd",
    component: _ae69fb6c,
    name: "admins-audit-jd"
  }, {
    path: "/admins/audit-order",
    component: _3f52a06e,
    name: "admins-audit-order"
  }, {
    path: "/admins/audit-real-name",
    component: _0f4ec47a,
    name: "admins-audit-real-name"
  }, {
    path: "/admins/audit-referee",
    component: _dd191180,
    name: "admins-audit-referee"
  }, {
    path: "/admins/audit-user",
    component: _e07f9b4a,
    name: "admins-audit-user"
  }, {
    path: "/admins/audit-withdraw",
    component: _2e491e1a,
    name: "admins-audit-withdraw"
  }, {
    path: "/admins/chat",
    component: _c5d9eb94,
    name: "admins-chat"
  }, {
    path: "/admins/op-carousel",
    component: _6f4be55e,
    name: "admins-op-carousel"
  }, {
    path: "/admins/op-home-hot",
    component: _831b3a46,
    name: "admins-op-home-hot"
  }, {
    path: "/admins/op-home-latest",
    component: _e24d2db2,
    name: "admins-op-home-latest"
  }, {
    path: "/admins/op-hot",
    component: _d795e182,
    name: "admins-op-hot"
  }, {
    path: "/admins/op-jd-time",
    component: _7e8547f2,
    name: "admins-op-jd-time"
  }, {
    path: "/admins/op-sensitive",
    component: _5af67188,
    name: "admins-op-sensitive"
  }, {
    path: "/admins/sub-company",
    component: _7696ed82,
    name: "admins-sub-company"
  }, {
    path: "/admins/sub-jd",
    component: _dc542476,
    name: "admins-sub-jd"
  }, {
    path: "/admins/sub-user",
    component: _61be2b16,
    name: "admins-sub-user"
  }, {
    path: "/profiles/account",
    component: _1de50ee5,
    name: "profiles-account"
  }, {
    path: "/profiles/auth",
    component: _39268fa0,
    name: "profiles-auth"
  }, {
    path: "/profiles/balance",
    component: _672bf7d8,
    name: "profiles-balance"
  }, {
    path: "/profiles/bank-card",
    component: _0893eeb9,
    name: "profiles-bank-card"
  }, {
    path: "/profiles/chat",
    component: _3974ed50,
    name: "profiles-chat"
  }, {
    path: "/profiles/company",
    component: _6811cd96,
    name: "profiles-company"
  }, {
    path: "/profiles/expense",
    component: _ecd580e0,
    name: "profiles-expense"
  }, {
    path: "/profiles/friend-fav",
    component: _6d4c9938,
    name: "profiles-friend-fav"
  }, {
    path: "/profiles/income",
    component: _14f71321,
    name: "profiles-income"
  }, {
    path: "/profiles/jd-fav",
    component: _65720a00,
    name: "profiles-jd-fav"
  }, {
    path: "/profiles/jd-ownership",
    component: _1e102294,
    name: "profiles-jd-ownership"
  }, {
    path: "/profiles/jd-referee",
    component: _58f5c1f6,
    name: "profiles-jd-referee"
  }, {
    path: "/profiles/job-fav",
    component: _417e5ac3,
    name: "profiles-job-fav"
  }, {
    path: "/profiles/my-cv",
    component: _644eed28,
    name: "profiles-my-cv"
  }, {
    path: "/profiles/my-jd",
    component: _4e66629a,
    name: "profiles-my-jd"
  }, {
    path: "/profiles/my-post",
    component: _0381c2d9,
    name: "profiles-my-post"
  }, {
    path: "/profiles/order-receive",
    component: _252ac89c,
    name: "profiles-order-receive"
  }, {
    path: "/profiles/post-receive",
    component: _95052ca4,
    name: "profiles-post-receive"
  }, {
    path: "/profiles/real-name",
    component: _3b2bd8dc,
    name: "profiles-real-name"
  }, {
    path: "/profiles/referee",
    component: _2e70a750,
    name: "profiles-referee"
  }, {
    path: "/profiles/refund",
    component: _28585d20,
    name: "profiles-refund"
  }, {
    path: "/profiles/service",
    component: _7753c76d,
    name: "profiles-service"
  }, {
    path: "/profiles/withdraw",
    component: _7d08e7fc,
    name: "profiles-withdraw"
  }, {
    path: "/",
    component: _0a0cc445,
    name: "index"
  }, {
    path: "/cvfs/:id?",
    component: _6a9e8908,
    name: "cvfs-id"
  }, {
    path: "/cvos/:id?",
    component: _0133831f,
    name: "cvos-id"
  }, {
    path: "/jds/:id?",
    component: _78eac652,
    name: "jds-id"
  }, {
    path: "/posts/:id?",
    component: _363c2971,
    name: "posts-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
