import areaJson from "../assets/datas/counties.json"

//==========省份相关========================

export function provinces() {
    return areaJson.result.Provinces
}

export function province(provinceId) {
    provinceId = provinceId || 0
    let ps = provinces()
    for (let i=0; i<ps.length;i++) {
        let p = ps[i]
        if (p._id===provinceId) {
            return p
        }
    }
    return null
}

export function provinceName(provinceId) {
    provinceId = provinceId || 0
    let p = province(provinceId)
    return p ? p.name : ""
}

export function firstProvinceId() {
    let ps = provinces()
    return ps.length>0 ? ps[0]._id : null
}

//==========城市相关========================

export function cities(provinceId) {
    provinceId = provinceId || 0
    let p = province(provinceId)
    return p ? p.children : []
}

export function city(cities,cityId) {
    cityId = cityId || 0
    for (let i=0; i<cities.length;i++) {
        let c = cities[i]
        if (c._id===cityId) {
            return c
        }
    }
    return null
}

export function cityName(cities,cityId) {
    cityId = cityId || 0
    let c = city(cities,cityId)
    return c ? c.name : ""
}

export function city2(provinceId,cityId) {
    let cs = cities(provinceId)
    return city(cs,cityId)
}

export function cityName2(provinceId,cityId) {
    let cs = cities(provinceId)
    return cityName(cs,cityId)
}

export function firstCityId(cities) {
    return cities && cities.length>0 ? cities[0]._id : null
}

//==========区县相关========================

export function county(counties,countyId) {
    countyId = countyId || 0
    for (let i=0; i<counties?.length;i++) {
        let c = counties[i]
        if (c._id===countyId) {
            return c
        }
    }
    return null
}

export function countyName(countries,countyId) {
    countyId = countyId || 0
    let c = county(countries,countyId)
    return c ? c.name : ""
}

export function counties(provinceId,cityId) {
    provinceId = provinceId || 0
    cityId = cityId || 0
    let c = city2(provinceId,cityId)
    return c ? c.children : []
}

export function county2(provinceId,cityId,countyId) {
    provinceId = provinceId || 0
    cityId = cityId || 0
    countyId = countyId || 0
    let cs = counties(provinceId,cityId)
    return county(cs,countyId)
}

export function countyName2(provinceId,cityId,countyId) {
    provinceId = provinceId || 0
    cityId = cityId || 0
    countyId = countyId || 0
    let cs = counties(provinceId,cityId)
    return countyName(cs,countyId)
}

export function firstCountyId(countries) {
    return countries && countries.length>0 ? countries[0]._id : null
}

export function areaName(provinceId,cityId,countyId) {
    provinceId = provinceId || 0
    cityId = cityId || 0
    countyId = countyId || 0

    let p,ci,co
    p = province(provinceId)
    if (p) {
        ci = city(p.children,cityId)
        if (ci) {
            co = county(ci.children,countyId)
        }
    }
    return {
        provinceName: p?.name,
        cityName: ci?.name,
        countyName: co?.name,
    }
}

