export const SEX_MALE = 1 //男
export const SEX_FEMALE = 2 //女

export const LOCK_INIT = 0
export const LOCK_YES = 1
export const LOCK_NO = 2

export const ROLE_USER = 1
export const ROLE_ADMIN = 2

export const defaultUser = {
    _id: 0,
    name: "",
    mobile: "",
    avatarKey: "",
    avatarUrl: "",
    roleId: 0,
    sexId: 0,
    introducerId: 0,
    locked: 0,
    invCode: "",
    introCode: "",
    canEditIntroCode: false,

    realNameId: 0,
    refereeId: 0,
    companyId: 0,
}
