export const DEFAULT_MQTT_BROKER_URL = "wss://91neitui.com:443/mqtt"

export const MQTT_BROKER_CLIENT_ID = "mqtt_"
export const MQTT_BROKER_USER = ""
export const MQTT_BROKER_PASSWORD = ""
export const MQTT_BROKER_CLEAN = false
export const MQTT_BROKER_KEEP_ALIVE = 5 * 60 //120
export const MQTT_BROKER_CONNECT_TIMEOUT = 30 * 1000 // 4000
export const MQTT_BROKER_RECONNECT_PERIOD = 1000

export const MQTT_EVENT_CONNECT = "connect"
export const MQTT_EVENT_RECONNECT = "reconnect"
export const MQTT_EVENT_DISCONNECT = "disconnect"
export const MQTT_EVENT_CLOSE = "close"
export const MQTT_EVENT_OFFLINE = "offline"
export const MQTT_EVENT_ERROR = "error"
export const MQTT_EVENT_END = "end"
export const MQTT_EVENT_MESSAGE = "message"
export const MQTT_EVENT_OUTGOING_EMPTY = "outgoingEmpty"
export const MQTT_EVENT_PACKET_SEND = "packetsend"
export const MQTT_EVENT_PACKET_RECEIVE = "packetreceive"

export const MQTT_TOPIC_CHAT = "91neitui/chat/room/roomId/inbox"
export const MQTT_TOPIC_QOS = 1
export const MQTT_TOPIC_RETAIN = false
