import * as rpc from "@/constants/rpc"
import RealName from "~/models/real-name";
import {STATUS_APPLYING} from "~/constants/audit";

export const state = () => ({
    realName: new RealName(),
})

export const mutations = {
    setRealName(state, realName) {
        state.realName = realName
    },
}

export const getters = {
    // isLogin (state, getters) {
    //     return state.accessToken && state.user && state.user._id > 0
    // },
    // isLoginUser(state, getters) {
    //     return getters.isLogin && state.user.roleId === 1
    // },
}

export const actions = {

    async getRealName(context, _id) {
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REAL_NAME_GET, {_id})
        if (ret && ret.result) {
            context.commit('setRealName', new RealName(ret.result))
        }
    },

    async applyNewRealName(context, realName) {
        const params = realName.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REAL_NAME_APPLY_NEW, params)
        if (ret && ret.result) {
            realName._id = ret.result._id
            realName.statusId = STATUS_APPLYING
            context.commit('user/setUserRealNameId', realName._id, {root: true})
            context.commit('setRealName', realName)
        }
        return ret
    },
    async applyUpdateRealName(context, realName) {
        const params = realName.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REAL_NAME_APPLY_UPDATE, params)
        if (ret && ret.result) {
            realName.statusId = STATUS_APPLYING
            context.commit('setRealName', realName)
        }
        return ret
    },
}
