//gateway
//export const PCWEB = "http://localhost.charlesproxy.com:25200"

//end points
export const AUTH = "/api/auth/v1"
export const USER = "/api/user/v1"
export const CONTENT = "/api/content/v1"
export const FINANCE = "/api/finance/v1"
export const SEARCH = "/api/search/v1"
export const CRON = "/api/cron/v1"
export const OPERATION = "/api/operation/v1"
export const FILE = "/api/file/v1/file"
export const FILES = "/api/file/v1/files"
export const CHAT = "/api/chat/v1"

//methods
//-- auth
export const AUTH_CREATE_CAPTCHA = "CaptchaService.CreateCaptcha"
export const AUTH_SEND_REGISTER_SMS = "SmsService.SendRegisterSms"
export const AUTH_SEND_LOGIN_SMS = "SmsService.SendLoginSms"
export const AUTH_SEND_RESET_SMS = "SmsService.SendResetSms"
export const AUTH_REGISTER = "UserService.Register"
export const AUTH_LOGIN_PWD = "UserService.LoginPassword"
export const AUTH_LOGIN_SMS = "UserService.LoginSmsCode"
export const AUTH_RESET = "UserService.ResetPassword"
export const AUTH_GET_LOGIN_USER = "UserService.GetLoginUser"

//--user
export const USER_GET_BY_ID = "UserService.GetUserById"
export const USER_UPDATE_BASIC = "UserService.UpdateBasic"
export const USER_LOCK = "UserService.LockUser"
export const USER_GET_PAGE = "UserService.GetUsersByPage"
export const USER_COUNT = "UserService.GetUsersCount"
export const USER_GET_BY_INTRO_CODE = "UserService.GetUserByIntroCode"
export const USER_GET_RANDOM_ADMIN = "UserService.GetRandomAdmin"

export const COMPANY_GET = "CompanyService.GetCompany"
export const COMPANY_APPLY_NEW = "CompanyService.ApplyNewCompany"
export const COMPANY_APPLY_UPDATE = "CompanyService.ApplyUpdateCompany"
export const COMPANY_QUIT = "CompanyService.QuitMyCompany"
export const COMPANY_CHANGE_TO_MY = "CompanyService.ChangeToMyCompany"
export const COMPANY_SEARCH = "CompanyService.SearchCompany"
export const COMPANY_IS_APPROVED = "CompanyService.IsCompanyApproved"
export const COMPANY_AUDIT = "CompanyService.AuditCompany"
export const COMPANY_GET_PAGE = "CompanyService.GetCompaniesByPage"
export const COMPANY_COUNT = "CompanyService.GetCompaniesCount"
export const COMPANY_GET_APPLYING = "CompanyService.GetApplyingCompaniesByPage"
export const COMPANY_COUNT_APPLYING = "CompanyService.GetApplyingCompaniesCount"

export const REFEREE_APPLY_NEW = "RefereeService.CreateReferee"
export const REFEREE_APPLY_UPDATE = "RefereeService.UpdateReferee"
export const REFEREE_GET = "RefereeService.GetRefereeById"
export const REFEREE_USER_GET = "RefereeService.GetRefereeUserByUserId"
export const REFEREE_USER_DEL = "RefereeService.DelRefereeUserByUserId"
export const REFEREE_AUDIT = "RefereeService.AuditReferee"
export const REFEREE_IS_APPROVED = "RefereeService.IsRefereeApproved"
export const REFEREE_GET_WITH_USER_COMPANY = "RefereeService.GetRefereeUserCompanyByUserId"
export const REFEREE_GET_PAGE_APPLYING = "RefereeService.GetApplyingRefereeUserCompanyByPage"
export const REFEREE_GET_COUNT_APPLYING = "RefereeService.GetApplyingRefereeUserCompanyCount"
export const REFEREE_GET_PAGE = "RefereeService.GetRefereeUserCompanyByPage"
export const REFEREE_GET_COUNT = "RefereeService.GetRefereeUserCompanyCount"

export const EMAIL_SEND_AUTH = "EmailService.SendCompanyEmailAuth"
export const EMAIL_VERIFY = "EmailService.VerifyCompanyEmail"

export const REAL_NAME_APPLY_NEW = "RealNameService.CreateRealName"
export const REAL_NAME_APPLY_UPDATE = "RealNameService.UpdateRealName"
export const REAL_NAME_GET = "RealNameService.GetRealNameById"
export const REAL_NAME_GET_BY_USER_ID = "RealNameService.GetRealNameByUserId"
export const REAL_NAME_GET_BY_ID_NAME = "RealNameService.GetRealNameByIdName"
export const REAL_NAME_GET_COUNT_BY_ID_NAME = "RealNameService.GetRealNameCountByIdName"
export const REAL_NAME_GET_BY_ID_NUM = "RealNameService.GetRealNameByIdNum"
export const REAL_NAME_AUDIT = "RealNameService.AuditRealName"
export const REAL_NAME_IS_APPROVED = "RealNameService.IsRealNameApproved"
export const REAL_NAME_GET_APPLYING_PAGE = "RealNameService.GetApplyingRealNamesByPage"
export const REAL_NAME_GET_APPLYING_COUNT = "RealNameService.GetApplyingRealNamesCount"
export const REAL_NAME_GET_PAGE = "RealNameService.GetRealNamesByPage"
export const REAL_NAME_GET_COUNT = "RealNameService.GetRealNamesCount"


//--search
export const SEARCH_HOT = "HotService.GetHotWords"
export const SEARCH_HOT_ADD = "HotService.AddHotWord"
export const SEARCH_HOT_REMOVE = "HotService.RemoveHotWord"

export const SEARCH_COMPANIES_BY_NAME = "CompanyService.SearchCompaniesByName"
export const SEARCH_USERS_BY_NAME = "UserService.SearchUsersByName"
export const SEARCH_USERS_BY_MOBILE = "UserService.SearchUsersByMobile"
export const SEARCH_JDS = "JdService.SearchJds"
export const SEARCH_RECOMMEND_JDS = "JdService.RecommendJds"
export const SEARCH_RECOMMEND_JDS_FOR_JD = "JdService.RecommendJdsForJd"

//---content
export const SERVICE_CREATE = "ServiceService.CreateMyService"
export const SERVICE_GET = "ServiceService.GetMyService"
export const SERVICE_UPDATE = "ServiceService.UpdateMyService"
export const SERVICE_TRY_TO_CREATE = "ServiceService.TryToCreateMyService"

export const JD_CREATE = "JdService.CreateJd"
export const JD_GET_BY_ID = "JdService.GetJdById"
export const JD_GET_BY_IDS = "JdService.GetJdsByIds"
export const JD_GET_MY_BY_PAGE = "JdService.GetMyJdsByPage"
export const JD_GET_MY_COUNT = "JdService.GetMyJdsCount"
export const JD_GET_USER_BY_PAGE = "JdService.GetUserJdsByPage"
export const JD_GET_USER_COUNT = "JdService.GetUserJdsCount"
export const JD_GET_BY_PAGE = "JdService.GetJdsByPage"
export const JD_GET_COUNT = "JdService.GetJdsCount"
export const JD_UPDATE_MY = "JdService.UpdateJd"
export const JD_RESUME_MY = "JdService.ResumeMyJd"
export const JD_UPDATE_MY_STATUS = "JdService.UpdateMyJdStatus"
export const JD_DEL_MY = "JdService.DeleteJd"
export const JD_AUDIT = "JdService.AuditJd"
export const JD_GET_MY_INVOLVED_COUNT = "JdService.GetMyInvolvedJdCount"
export const JD_GET_MY_INVOLVED_BY_PAGE = "JdService.GetMyInvolvedJdPage"

export const USER_FAV_GET_MY_BY_PAGE = "UserFavService.GetMyUserFavsByPage"
export const USER_FAV_GET_MY_COUNT = "UserFavService.GetMyUserFavCount"
export const USER_FAV_CREATE = "UserFavService.CreateUserFav"
export const USER_FAV_DEL = "UserFavService.DeleteUserFav"

export const JOB_FAV_GET_MY = "JobFavService.GetMyJobFavs"
export const JOB_FAV_CREATE = "JobFavService.CreateJobFav"
export const JOB_FAV_DEL = "JobFavService.DeleteJobFav"

export const JD_FAV_GET_MY_BY_PAGE = "JdFavService.GetMyJdFavsByPage"
export const JD_FAV_GET_MY_COUNT = "JdFavService.GetMyJdFavCount"
export const JD_FAV_CREATE = "JdFavService.CreateJdFav"
export const JD_FAV_DEL = "JdFavService.DeleteJdFav"
export const JD_FAV_CHECK = "JdFavService.CheckMyJdFav"

export const JD_REFEREE_CREATE = "JdRefereeService.CreateJdReferee"
export const JD_REFEREE_DELETE = "JdRefereeService.DeleteJdReferee"
export const JD_REFEREE_GET_APPROVED = "JdRefereeService.GetApprovedJdRefereesByJdId"
export const JD_REFEREE_GET_PAGE_BY_MY_RECEIVED = "JdRefereeService.GetPageByMyReceived"
export const JD_REFEREE_GET_COUNT_BY_MY_RECEIVED = "JdRefereeService.GetCountByMyReceived"
export const JD_REFEREE_GET_PAGE_BY_MY_APPLIED = "JdRefereeService.GetPageByMyApplied"
export const JD_REFEREE_GET_COUNT_BY_MY_APPLIED = "JdRefereeService.GetCountByMyApplied"
export const JD_REFEREE_UPDATE_STATUS = "JdRefereeService.UpdateJdRefereeStatus"

export const JD_OWNERSHIP_CREATE = "JdOwnershipService.CreateJdOwnership"
export const JD_OWNERSHIP_GET_PAGE_BY_MY_RECEIVED = "JdOwnershipService.GetPageByMyReceived"
export const JD_OWNERSHIP_GET_COUNT_BY_MY_RECEIVED = "JdOwnershipService.GetCountByMyReceived"
export const JD_OWNERSHIP_GET_PAGE_BY_MY_APPLIED = "JdOwnershipService.GetPageByMyApplied"
export const JD_OWNERSHIP_GET_COUNT_BY_MY_APPLIED = "JdOwnershipService.GetCountByMyApplied"
export const JD_OWNERSHIP_UPDATE_STATUS = "JdOwnershipService.UpdateJdOwnershipStatus"

export const JD_UPDATE_TIME_BY_JD_ID = "JdService.UpdateJdTimeByJdId"
export const JD_UPDATE_TIME_BY_JD_RANGE = "JdService.UpdateJdTimeByJdRange"
export const JD_UPDATE_TIME_BY_USER_ID = "JdService.UpdateJdTimeByUserId"
export const JD_UPDATE_TIME_BY_COMPANY_ID = "JdService.UpdateJdTimeByCompanyId"

export const CV_GET_MY = "CvService.GetMyCvs"
export const CV_CREATE = "CvService.CreateCv"
export const CV_UPDATE = "CvService.UpdateCv"
export const CV_DELETE = "CvService.DeleteCv"
//export const CV_CLONE = "CvService.CloneCv"

export const CVO_GET = "CvoService.GetCvoById"
export const CVO_CLONE = "CvoService.CloneCvo"

export const CVO_BASIC_CREATE = "CvoService.CreateCvoBasic"
export const CVO_BASIC_UPDATE = "CvoService.UpdateCvoBasic"

export const CVO_ADV_CREATE = "CvoService.CreateCvoAdvantage"
export const CVO_ADV_UPDATE = "CvoService.UpdateCvoAdvantage"

export const CVO_INTENT_CREATE = "CvoService.CreateCvoIntent"
export const CVO_INTENT_UPDATE = "CvoService.UpdateCvoIntent"
export const CVO_INTENT_DELETE = "CvoService.DeleteCvoIntent"

export const CVO_WORK_EXP_CREATE = "CvoService.CreateCvoWorkExp"
export const CVO_WORK_EXP_UPDATE = "CvoService.UpdateCvoWorkExp"
export const CVO_WORK_EXP_DELETE = "CvoService.DeleteCvoWorkExp"

export const CVO_PROJECT_EXP_CREATE = "CvoService.CreateCvoProjectExp"
export const CVO_PROJECT_EXP_UPDATE = "CvoService.UpdateCvoProjectExp"
export const CVO_PROJECT_EXP_DELETE = "CvoService.DeleteCvoProjectExp"

export const CVO_SOCIAL_CREATE = "CvoService.CreateCvoSocial"
export const CVO_SOCIAL_UPDATE = "CvoService.UpdateCvoSocial"
export const CVO_SOCIAL_DELETE = "CvoService.DeleteCvoSocial"

export const CVO_EDU_CREATE = "CvoService.CreateCvoEducation"
export const CVO_EDU_UPDATE = "CvoService.UpdateCvoEducation"
export const CVO_EDU_DELETE = "CvoService.DeleteCvoEducation"

export const CVO_ATTACHMENT_UPDATE = "CvoService.UpdateCvoAttachments"

export const CVF_CREATE = "CvfService.CreateCvf"
export const CVF_GET = "CvfService.GetCvf"
export const CVF_UPDATE = "CvfService.UpdateCvf"
export const CVF_DELETE = "CvfService.DeleteCvf"
export const CVF_CLONE = "CvfService.CloneCvf"

export const POST_CREATE = "PostService.CreatePost"
export const POST_GET_BY_ID = "PostService.GetPostById"
export const POST_GET_PAGE_BY_USER_ID = "PostService.GetPageByUserId"
export const POST_GET_COUNT_BY_USER_ID = "PostService.GetCountByUserId"
export const POST_GET_PAGE_BY_REFEREE_USER_ID = "PostService.GetPageByRefereeUserId"
export const POST_GET_COUNT_BY_REFEREE_USER_ID = "PostService.GetCountByRefereeUserId"
export const POST_UPDATE_STATUS = "PostService.UpdatePostStatus"
export const POST_UPDATE_CV_ID = "PostService.UpdatePostCvId"
export const POST_GET_REFEREE_WITH_SERVICE_BY_USER_ID = "PostService.GetRefereeBoWithServiceByUserId"
export const POST_ALARM = "PostService.AlarmPost"
export const POST_HAS_BY_REFEREE_AND_JD_ID = "PostService.HasPostByRefereeUserAndJdId"

export const RANK_JD_LATEST = "RankService.GetJdLatestRank"
export const RANK_JD_HOT = "RankService.GetJdHotRank"

//---finance
export const ORDER_GET_OR_CREATE = "OrderService.GetOrCreateNewOrder"
export const ORDER_PAY = "OrderService.PayOrder"
export const ORDER_CANCEL = "OrderService.CancelOrder"
export const ORDER_SERVICE_DONE = "OrderService.ServiceDoneOrder"
export const ORDER_COMPLAINT_DONE = "OrderService.ComplaintDoneOrder"
export const ORDER_GET_BY_POST_ID_AND_SERVICE_ID = "OrderService.GetOrderBoByPostIdAndServiceId"
export const ORDER_GET_BY_POST_ID = "OrderService.GetOrderBosByPostId"
export const ORDER_GET_MY_INCOME_ORDER_COUNT = "OrderService.GetMyIncomeOrderCount"
export const ORDER_GET_MY_INCOME_ORDER_PAGE = "OrderService.GetMyIncomeOrderPage"
export const ORDER_GET_MY_PAID_ORDER_COUNT = "OrderService.GetMyPaidOrderCount"
export const ORDER_GET_MY_PAID_ORDER_PAGE = "OrderService.GetMyPaidOrderPage"
export const ORDER_GET_SHARABLE_ORDER_PAGE = "OrderService.GetSharableOrdersByPage"
export const ORDER_CREATE_ORDER_SHARES = "OrderService.ShareOrder"
export const ORDER_HAS_PAID_BY_REFEREE_AND_JD_ID = "OrderService.HasPaidOrderByRefereeUserAndJdId"
export const ORDER_GET_COUNT = "OrderService.GetOrderCount"
export const ORDER_GET_PAGE = "OrderService.GetOrderPage"
export const ORDER_GET_BY_ID = "OrderService.GetOrderById"

export const TREATMENT_CREATE = "TreatmentService.CreateTreatment"
export const TREATMENT_DELETE_BY_ID = "TreatmentService.DeleteTreatment"
export const TREATMENT_UPDATE = "TreatmentService.UpdateTreatment"

export const COMMENT_ROOT_CREATE = "CommentService.CreateRootComment"
export const COMMENT_ROOT_UPDATE = "CommentService.UpdateRootComment"
export const COMMENT_DELETE_BY_ID = "CommentService.DeleteComment"

export const MR_CREATE = "MeetingRequestService.CreateMeetingRequest"
export const MR_DELETE_BY_ID = "MeetingRequestService.DeleteMeetingRequest"
export const MR_UPDATE = "MeetingRequestService.UpdateMeetingRequest"

export const MEETING_CREATE = "MeetingService.CreateMeeting"
export const MEETING_DELETE_BY_ID = "MeetingService.DeleteMeeting"
export const MEETING_UPDATE = "MeetingService.UpdateMeeting"

export const SHARE_GET_BY_ID = "ShareService.GetShareById"
export const SHARE_GET_MY_COUNT = "ShareService.GetMyShareCount"
export const SHARE_GET_MY_PAGE = "ShareService.GetMySharePage"

export const BALANCE_GET_MY = "BalanceService.GetMyBalance"

export const WITHDRAW_GET_MY_COUNT = "WithdrawService.GetMyWithdrawCount"
export const WITHDRAW_GET_MY_PAGE = "WithdrawService.GetMyWithdrawPage"
export const WITHDRAW_PRE_MY_WITHDRAW = "WithdrawService.PreMyWithdraw"
export const WITHDRAW_CREATE_MY_WITHDRAW = "WithdrawService.CreateMyWithdraw"
export const WITHDRAW_AUDIT = "WithdrawService.AuditWithdraw"
export const WITHDRAW_GET_PAGE = "WithdrawService.GetWithdrawsByPage"
export const WITHDRAW_COUNT = "WithdrawService.GetWithdrawsCount"
export const WITHDRAW_GET_APPLYING = "WithdrawService.GetApplyingWithdrawsByPage"
export const WITHDRAW_COUNT_APPLYING = "WithdrawService.GetApplyingWithdrawsCount"
export const WITHDRAW_GET_BY_ID = "WithdrawService.GetWithdrawById"
export const WITHDRAW_GET_BY_CODE = "WithdrawService.GetWithdrawByCode"

export const REFUND_CREATE = "OrderService.CreateOrderRefund"
export const REFUND_GET_MY_COUNT = "RefundService.GetMyRefundCount"
export const REFUND_GET_MY_PAGE = "RefundService.GetMyRefundPage"

export const BANK_CARD_CREATE_MY = "BankCardService.CreateMyBankCard"
export const BANK_CARD_UPDATE_MY = "BankCardService.UpdateMyBankCard"
export const BANK_CARD_GET_MY = "BankCardService.GetMyBankCard"
export const BANK_CARD_GET_BY_ID = "BankCardService.GetBankCardById"

export const SQB_PRE_CREATE = "SqbService.PreCreate"
export const SQB_QUERY = "SqbService.Query"
export const SQB_REFUND = "SqbService.Refund"
export const SQB_CANCEL = "SqbService.Cancel"
export const SQB_REVOKE = "SqbService.Revoke"

//---operation

export const CAROUSEL_GET = "CarouselService.GetCarousels"
export const CAROUSEL_UPSET = "CarouselService.UpsetCarousels"

export const HOME_JD_LATEST_LIST = "RankService.GetJdIdsLatestRank"
export const HOME_JD_LATEST_ADD_BY_RANGE = "RankService.TestAddJdLatestScores"
export const HOME_JD_LATEST_ADD_BY_ID = "RankService.TestAddJdLatestScore"
export const HOME_JD_LATEST_DEL_BY_ID = "RankService.TestDeleteJdLatestScore"

export const HOME_JD_HOT_LIST = "RankService.GetJdIdsHotRank"
export const HOME_JD_HOT_ADD_BY_RANGE = "RankService.TestAddJdHotScores"
export const HOME_JD_HOT_ADD_BY_ID = "RankService.TestAddJdHotScore"
export const HOME_JD_HOT_DEL_BY_ID = "RankService.TestDeleteJdHotScore"

export const SENSITIVE_BASE_PAGE = "FilterService.GetBaseWordPage"
export const SENSITIVE_BASE_COUNT = "FilterService.GetBaseWordCount"
export const SENSITIVE_BASE_DEL = "FilterService.DelBaseWord"
export const SENSITIVE_BASE_FIND = "FilterService.FindBaseWord"
export const SENSITIVE_ADDITION_ADD = "FilterService.AddAdditionWord"
export const SENSITIVE_ADDITION_DEL = "FilterService.DelAdditionWord"
export const SENSITIVE_ADDITION_LIST = "FilterService.GetAdditionList"
export const SENSITIVE_SHIELD_ADD = "FilterService.AddShieldWord"
export const SENSITIVE_SHIELD_DEL = "FilterService.DelShieldWord"
export const SENSITIVE_SHIELD_LIST = "FilterService.GetShieldList"
export const SENSITIVE_TEST = "FilterService.TestSensitiveWords"


//---chat
export const CHAT_ROOM_GET_OR_CREATE_PRIVATE = "ChatRoomService.GetOrCreatePrivate"
export const CHAT_ROOM_GET_MY_PAGE = "ChatRoomService.GetMyRoomPage"
export const CHAT_ROOM_GET_MY_COUNT = "ChatRoomService.GetMyRoomCount"
export const CHAT_ROOM_RENAME = "ChatRoomService.UpdateRoomName"
export const CHAT_ROOM_LIST_MEMBER = "ChatRoomService.ListMemberByRoomId"
export const CHAT_ROOM_SET_READ = "ChatMessageService.SetRoomRead"

export const CHAT_MSG_GET_PAGE = "ChatMessageService.GetMessagesByPage"
export const CHAT_MSG_GET_COUNT = "ChatMessageService.GetMessagesCount"


//upload url
export const URL_OSS_PREFIX = "https://91neitui.oss-cn-beijing.aliyuncs.com"
export const URL_OSS_CHAT_PREFIX = URL_OSS_PREFIX + "/chat"

//--uploader
export const URL_AVATAR = FILE + "?type=avatar"
export const URL_LOGO_COMPANY = FILE + "?type=company"
export const URL_LOGO_SCHOOL = FILE + "?type=school"
export const URL_ID_CARD = FILE + "?type=idcard"
export const URL_CV = FILE + "?type=cv"
export const URL_CAROUSEL = FILE + "?type=carousel"
export const URL_CHAT = FILE + "?type=chat"

