import companyStatusJson from "../assets/datas/company-status.json"
import {
    STATUS_APPLYING,
    STATUS_APPROVED_WITH_APPLYING,
    STATUS_APPROVED_WITH_REJECTED,
    STATUS_NEW,
    STATUS_OFFLINE_BY_OWNER
} from "@/constants/audit";

export function getCompanyStatuses() {
    return companyStatusJson.result.filter(function (item) {
        return item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getSettableCompanyStatuses() {
    return companyStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getAdminSettableCompanyStatuses() {
    return companyStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_APPLYING
            && item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

//=====

export function getCompanyStatusName(id) {
    for (let i = 0; i < companyStatusJson.result.length; i++) {
        let obj = companyStatusJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return "状态未知"
}

export function getCompanyStatusDesc(id) {
    for (let i = 0; i < companyStatusJson.result.length; i++) {
        let obj = companyStatusJson.result[i]
        if (obj._id == id) {
            return obj.desc
        }
    }
    return ""
}
