
import rules from "@/utils/account";

export default {
    name: "RegisterDialog",
    props: {
        isVisible: Boolean,
    },
    data() {
        return {
            form: {
                mobile: '',
                captcha: {
                    captchaId: "",
                    captchaCode: "",
                    captchaB64Image: "",
                },
                smsCode: {
                    value: "",
                    text: "获取验证码",
                    disable: false,
                    counter: 60,
                },
                password: '',
                repassword: '',
                introCode: '',
                agreements: [],
            },
            hasIntro: false,
        }
    },
    computed: {
        formRules() {
            return rules(this.form)
        },
    },
    methods: {
        async getCaptcha() {
            const ret = await this.$store.dispatch('user/getCaptcha')
            if (ret && ret.result) {
                this.form.captcha.captchaId = ret.result.captchaId
                this.form.captcha.captchaB64Image = ret.result.captchaB64Image
            }
        },
        captchaClicked() {
            this.getCaptcha()
        },
        onOpen() {
            this.$nextTick(() => {
                this.$refs['form'].resetFields();
            })
            this.getCaptcha()
        },
        smsCodeClicked() {
            this.$nextTick(() => {
                this.$refs['form'].clearValidate(['smsCode.value', 'password', 'repassword', 'introCode']);

                let fields = ["mobile", "captcha.captchaCode"]
                let okCounter = 0
                this.$refs['form'].validateField(fields, (errorMsg) => {
                    if (!errorMsg) {
                        okCounter++
                    } else {
                        console.log('validate failed!!');
                        return false;
                    }
                });

                if (okCounter === fields.length) {
                    this.getSmsCode()
                }
            })
        },
        setSmsCodeCounter() {
            let interval = setInterval(() => {
                if (this.form.smsCode.counter < 1) {
                    this.form.smsCode.disable = false
                    this.form.smsCode.text = "获取验证码"
                    this.form.smsCode.counter = 60
                    clearInterval(interval);
                } else {
                    this.form.smsCode.disable = true
                    this.form.smsCode.text = this.form.smsCode.counter-- + "秒后可重发";
                }
            }, 1000)
        },
        async getSmsCode() {
            const params = {
                mobile: this.form.mobile,
                captchaId: this.form.captcha.captchaId,
                captchaCode: this.form.captcha.captchaCode,
            }
            const ret = await this.$store.dispatch('user/getRegisterSmsCode', params)
            if (ret && ret.result) {
                this.setSmsCodeCounter()
                this.$message({
                    type: 'success',
                    message: '短信验证码已发出!'
                });
            }
            if (ret && ret.error) {
                await this.getCaptcha()
                this.$message({
                    type: 'error',
                    message: ret.error.message,
                });
            }
        },
        onClose() {
            this.$emit('update:isVisible', false);
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.register()
                } else {
                    console.log('validate failed!!');
                    return false;
                }
            });
        },
        onInvite() {
            this.hasIntro = true
        },
        introCodeClicked() {
            this.$nextTick(() => {

                let fields = ["introCode"]
                let okCounter = 0
                this.$refs['form'].validateField(fields, (errorMsg) => {
                    if (!errorMsg) {
                        okCounter++
                    } else {
                        console.log('validate failed!!');
                        return false;
                    }
                });

                if (okCounter === fields.length) {
                    this.showIntroCode()
                }
            })
        },
        async register() {
            const params = {
                mobile: this.form.mobile,
                password: this.form.password,
                smsCode: this.form.smsCode.value,
                introCode: this.form.introCode,
            }
            const ret = await this.$store.dispatch('user/register', params)
            if (ret && ret.result) {
                this.onClose()
                this.$message({
                    type: 'success',
                    message: '注册成功!'
                });
            }
            if (ret && ret.error) {
                await this.getCaptcha()
                this.$message({
                    type: 'error',
                    message: ret.error.message,
                });
            }
        },
        async showIntroCode() {
            const p = {
                introCode: this.form.introCode
            }
            const ret = await this.$store.dispatch('user/getIntroUser', p)
            if (ret && ret.result) {
                this.$message({
                    type: 'success',
                    message: "您的邀请人用户ID为:" + ret.result._id + ",用户昵称为:" + ret.result.name
                });
            }
        }

    }

}
