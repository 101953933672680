import {CV_FILE, CV_ONLINE} from "@/constants/cv";

export default class Cv {

    constructor(options) {
        if (options?.cv) {
            this._id = options?.cv?._id || 0
            this.userId = options?.cv?.userId || 0
            this.name = options?.cv?.name || ""
            this.typeId = options?.cv?.typeId || 0
            this.createdAt = options?.cv?.createdAt || ""
            this.updatedAt = options?.cv?.updatedAt || ""
        } else {
            this._id = options?._id || 0
            this.userId = options?.userId || 0
            this.name = options?.name || ""
            this.typeId = options?.typeId || 0
            this.createdAt = options?.createdAt || ""
            this.updatedAt = options?.updatedAt || ""
        }
    }

    get isNew() {
        return this._id <= 0
    }

    get typeName() {
        switch (this.typeId) {
            case CV_ONLINE:
                return "在线简历"
            case CV_FILE:
                return "文件简历"
            default:
                return "未知类型"
        }
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            userId: this.userId,
            name: this.name,
            typeId: this.typeId,
        }
    }

    clone() {
        let options = this.toData()
        return new Cv(options)
    }

    toJSON() {
        return {...this}
    }
}
