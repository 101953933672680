import code from "@/utils/code";
import {defaultUser} from "@/constants/user";
import * as rpc from "@/constants/rpc"
import Company from "~/models/company";
import Referee from "~/models/referee";
import RealName from "~/models/real-name";
import Cvo from "@/models/cvo";

export const state = () => ({
    user: {...defaultUser},
    accessToken: null,
    refreshToken: null,
    loginDialogVisible: false,
})

export const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setAccessToken(state, accessToken) {
        state.accessToken = accessToken
    },
    setRefreshToken(state, refreshToken) {
        state.refreshToken = refreshToken
    },
    updateBasic(state, basic) {
        state.user.name = basic.name
        state.user.avatarKey = basic.avatarKey
        state.user.avatarUrl = basic.avatarUrl
        state.user.sexId = basic.sexId
        if (state.user.canEditIntroCode && basic.introCode) {
            state.user.introCode = basic.introCode
        }
    },
    setUserCompanyId(state, companyId) {
        state.user.companyId = companyId
    },
    setUserRefereeId(state, refereeId) {
        state.user.refereeId = refereeId
    },
    setUserRealNameId(state, realNameId) {
        state.user.realNameId = realNameId
    },
    setLoginDialogVisible(state,isVisible) {
        state.loginDialogVisible = isVisible
    },
}

export const getters = {
    isLogin (state, getters) {
        return state.accessToken && state.user && state.user._id > 0
    },
    isManualLogin (state, getters) {
        return getters.isLogin && !state.loginDialogVisible
    },
    isLoginUser(state, getters) {
        return getters.isLogin && state.user.roleId === 1
    },
    isLoginAdmin(state, getters) {
        return getters.isLogin && state.user.roleId === 2
    },
    hasCompanyId(state, getters) {
        return state.user.companyId > 0
    },
    hasRefereeId(state, getters) {
        return state.user.refereeId > 0
    },
    hasRealNameId(state, getters) {
        return state.user.realNameId > 0
    },
    companyId(state, getters) {
        return state.user.companyId
    },
    refereeId(state, getters) {
        return state.user.refereeId
    },
    realNameId(state, getters) {
        return state.user.realNameId
    },
}

export const actions = {

    // 获取验证码及图片
    async getCaptcha() {
        return await this.$axios.rpc(rpc.AUTH, rpc.AUTH_CREATE_CAPTCHA)
    },

    // 获取账号注册短信验证码
    async getRegisterSmsCode(context, params) {
        return await this.$axios.rpc(rpc.AUTH, rpc.AUTH_SEND_REGISTER_SMS,params)
    },

    // 获取短信登录验证码
    async getLoginSmsCode(context, params) {
        return await this.$axios.rpc(rpc.AUTH, rpc.AUTH_SEND_LOGIN_SMS,params)
    },

    // 获取密码重置短信验证码
    async getResetSmsCode(context, params) {
        return await this.$axios.rpc(rpc.AUTH, rpc.AUTH_SEND_RESET_SMS,params)
    },

    // 登录成功后的处理
    async handleLogin(context, ret) {
        if (ret && ret.result) {
            this.$cookies.set("access_token", ret.result.accessToken)
            this.$cookies.set("refresh_token", ret.result.refreshToken)
            this.$cookies.set("uid", ret.result.uid)
            context.commit('setAccessToken', ret.result.accessToken)
            context.commit('setRefreshToken', ret.result.accessToken)
            await context.dispatch('getLoginUser')

            let state = code.deepClone(context.rootState)
            state.user.loginDialogVisible = false
            sessionStorage.setItem('store', JSON.stringify(state))
            localStorage.setItem('store', JSON.stringify(state))
        }
    },
    // 注册，并自动登录
    async register(context, { mobile, password, smsCode, introCode } ) {
        const params = {
            mobile: mobile,
            md5pwd: code.md5(password),
            smsCode: smsCode,
            platform: "web",
            introCode: introCode
        }
        const ret = await this.$axios.rpc(rpc.AUTH, rpc.AUTH_REGISTER,params)
        await context.dispatch('handleLogin',ret)
        return ret
    },

    async resetPassword(context, { mobile, password, smsCode } ) {
        const params = {
            mobile: mobile,
            md5pwd: code.md5(password),
            smsCode: smsCode,
        }
        const ret = await this.$axios.rpc(rpc.AUTH, rpc.AUTH_RESET,params)
        //await context.dispatch('handleLogin',ret)
        return ret
    },

    // 登录，获取token
    async login(context, { mobile, password, captchaId, captchaCode } ) {
        const params = {
            mobile: mobile,
            md5pwd: code.md5(password),
            captchaId: captchaId,
            captchaCode: captchaCode,
            platform: "web"
        }
        const ret = await this.$axios.rpc(rpc.AUTH, rpc.AUTH_LOGIN_PWD,params)
        await context.dispatch('handleLogin',ret)
        return ret
    },

    // 登录成功后获取登录用户信息
    async getLoginUser(context) {
        const ret = await this.$axios.rpc(rpc.AUTH, rpc.AUTH_GET_LOGIN_USER)
        if (ret && ret.result) {
            return context.commit('setUser', ret.result)
        }
        return context.commit('setUser', defaultUser)
    },

    // 退出登录
    async logout(context) {
        await context.dispatch('clear')
    },

    async clear(context) {
        context.commit('setUser', defaultUser)
        context.commit('company/setCompany', new Company(), {root: true})
        context.commit('referee/setReferee', new Referee(), {root: true})
        context.commit('real-name/setRealName', new RealName(), {root: true})
        context.commit('cvo/setCvo', new Cvo(), {root: true})

        context.commit('setAccessToken', null)
        context.commit('setRefreshToken', null)
        // this.$cookies.set('access_token',"")
        // this.$cookies.set('refresh_token', "")
        // this.$cookies.set('uid', "")
        this.$cookies.remove('access_token')
        this.$cookies.remove('refresh_token')
        this.$cookies.remove('uid')

        sessionStorage.setItem('store', "")
        localStorage.setItem('store', "")
    },

    async updateBasic(context, { name, avatarKey, avatarUrl, sexId, introCode } ) {
        let params = {
            name,
            avatarKey,
            avatarUrl,
            sexId,
        }
        if (introCode) {
            params.introCode = introCode
        }
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.USER_UPDATE_BASIC,params)
        if (ret && ret.result) {
            context.commit('updateBasic', params)
        }
        return ret
    },

    async getIntroUser(context, { introCode }) {
        return await this.$axios.rpcMsg(rpc.USER, rpc.USER_GET_BY_INTRO_CODE,{ introCode })
    },
}
