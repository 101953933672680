import experienceJson from "../assets/datas/experiences.json"
import degreeJson from "../assets/datas/degrees.json"
import salaryJson from "../assets/datas/salaries.json"
import stageJson from "../assets/datas/stages.json"
import scaleJson from "../assets/datas/scales.json"
import industryJson from "../assets/datas/industries.json"
import jobJson from "../assets/datas/jobs.json"
import code from "@/utils/code";


export function getExperiences() {
    return experienceJson.result
}

export function getDegrees() {
    return degreeJson.result
}

export function getSalaries() {
    return salaryJson.result
}

export function getStages() {
    return stageJson.result
}

export function getScales() {
    return scaleJson.result
}

export function getIndustries() {
    return industryJson.result
}

export function getJobs() {
    return jobJson.result
}

//=====

export function getExperienceName(id) {
    for (let i = 0; i < experienceJson.result.length; i++) {
        let obj = experienceJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

export function getDegreeName(id) {
    for (let i = 0; i < degreeJson.result.length; i++) {
        let obj = degreeJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

export function getSalaryName(id) {
    for (let i = 0; i < salaryJson.result.length; i++) {
        let obj = salaryJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

export function salaryName(salaryId, salaryTo) {
    salaryId = salaryId || 0
    salaryTo = salaryTo || 0
    //没有salaryId，通过salaryTo计算
    if (salaryId <= 0 && salaryTo > 0) {
        salaryId = getSalaryIdBySalaryTo(salaryTo)
    }
    return getSalaryName(salaryId)
}

function getSalaryBySalaryTo(salaryTo) {
    for (let i = salaryJson.result.length -1; i>=0; i--) {
        let obj = salaryJson.result[i]
        if (salaryTo > obj.from) {
            return obj
        }
    }
    return null
}

export function getSalaryIdBySalaryTo(salaryTo) {
    let s = getSalaryBySalaryTo(salaryTo)
    return s ? s._id : null
}

export function getSalaryNameBySalaryTo(salaryTo) {
    let s = getSalaryBySalaryTo(salaryTo)
    return s ? s.name : ""
}

export function getStageName(id) {
    for (let i = 0; i < stageJson.result.length; i++) {
        let obj = stageJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

export function getScaleName(id) {
    for (let i = 0; i < scaleJson.result.length; i++) {
        let obj = scaleJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

export function getIndustryName(id) {
    for (let i = 0; i < industryJson.result.length; i++) {
        let obj = industryJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return ""
}

//===

function node(list, v) {
    for (let i = 0; i < list.length; i++) {
        let n = list[i]
        if (n._id === v) {
            return n
        }
    }
    return null
}

export function getJobText(levels) {
    let text = ""
    let jobs = getJobs()

    let level0 = node(jobs, levels[0])
    if (level0) {
        text += level0.name
        let level1 = node(level0.children, levels[1])
        if (level1) {
            text += "/" + level1.name
            let level2 = node(level1.children, levels[2])
            if (level2) {
                text += "/" + level2.name
            }
        }
    }
    return text
}

export function getJobValue(jobCatId, jobSubCatId, jobId) {
    let jobs = getJobs()
    let jobCat = node(jobs, jobCatId)
    if (jobCat) {
        let jobSubCat = node(jobCat.children, jobSubCatId)
        if (jobSubCat) {
            let job = node(jobSubCat.children, jobId)
            if (job) {
                return job.name
            }
        }
    }
    return ""
}

export function getJobCascadeOptions() {
    return {
        value: "_id",
        label: "name",
        children: "children",
        // multiple: true,
        // checkStrictly: true,
        // expandTrigger: 'hover',
        // checkStrictly: true,
    }
}

export function salaryMultipleName(salaryMultiple) {
    let multi = code.isFloat(salaryMultiple)
        ? salaryMultiple.toFixed(2)
        : salaryMultiple
    return multi + "薪"
}
