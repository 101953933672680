import {
    STATUS_APPLYING, STATUS_APPROVED, STATUS_APPROVED_WITH_APPLYING, STATUS_APPROVED_WITH_REJECTED,
    STATUS_NEW, STATUS_OFFLINE_BY_ADMIN, STATUS_REJECTED
} from "@/constants/audit";
import {getRealNameStatusName} from "@/utils/real-name";

export default class RealName {

    constructor(options) {
        this._id = options?._id || 0

        this.userId = options?.userId || 0
        this.name = options?.name || ""
        this.idCardNum = options?.idCardNum || ""
        this.idCardFrontKey = options?.idCardFrontKey || ""
        this.idCardFrontUrl = options?.idCardFrontUrl || ""
        this.idCardBackKey = options?.idCardBackKey || ""
        this.idCardBackUrl = options?.idCardBackUrl || ""
        this.idCardInHandKey = options?.idCardInHandKey || ""
        this.idCardInHandUrl = options?.idCardInHandUrl || ""

        this.statusId = options?.statusId || 0
        this.statusText = options?.statusText || "-"
        this.auditExtra = options?.auditExtra || ""
    }

    get statusShort() {
        return getRealNameStatusName(this.statusId)
    }

    get isApproved() {
        return this.statusId == STATUS_APPROVED
    }

    get isAuditApplying() {
        return this.statusId == STATUS_APPLYING
            || this.statusId == STATUS_APPROVED_WITH_APPLYING
    }

    get isAuditApproved() {
        return this.statusId == STATUS_APPROVED
    }

    get isAuditRejected() {
        return this.statusId == STATUS_REJECTED
            || this.statusId == STATUS_APPROVED_WITH_REJECTED
    }

    get isAuditOfflineByAdmin() {
        return this.statusId == STATUS_OFFLINE_BY_ADMIN
    }

    get isAuditNothing() {
        return this.statusId == STATUS_NEW
    }

    get isNew() {
        return this._id <= 0
    }

    get auditRealName() {
        return new RealName(this.auditExtra)
    }

    get hasAuditRealName() {
        return !this.auditRealName.isNew
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = this.statusId
        vo.statusText = this.statusText
        vo.auditExtra = this.auditExtra
        return vo
    }

    toCreateData() {
        let vo = this.commonData()
        vo.statusId = STATUS_APPLYING
        return vo
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = STATUS_APPLYING
        return vo
    }

    commonData() {
        return {
            userId: this.userId,
            name: this.name,
            idCardNum: this.idCardNum,
            idCardFrontKey: this.idCardFrontKey,
            idCardFrontUrl: this.idCardFrontUrl,
            idCardBackKey: this.idCardBackKey,
            idCardBackUrl: this.idCardBackUrl,
            idCardInHandKey: this.idCardInHandKey,
            idCardInHandUrl: this.idCardInHandUrl,
        }
    }

    clone() {
        let options = this.toData()
        return new RealName(options)
    }

    toJSON () {
        return { ...this }
    }
}
