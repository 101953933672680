
import rules from "@/utils/account";

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
    name: "LoginDialog",
    // props: {
    //     isVisible: Boolean,
    // },
    data() {
        return {
            form: {
                mobile: '',
                captcha:{
                    captchaId: "",
                    captchaCode: "",
                    captchaB64Image: "",
                },
                password: '',
            },
        }
    },
    computed: {
        ...mapState({
            loginDialogVisible: state => state.loginDialogVisible
        }),
        formRules() {
            return rules(this.form)
        },
    },
    methods: {
        async getCaptcha() {
            const ret = await this.$store.dispatch('user/getCaptcha')
            if (ret && ret.result) {
                this.form.captcha.captchaId = ret.result.captchaId
                this.form.captcha.captchaB64Image = ret.result.captchaB64Image
            }
        },
        captchaClicked() {
            this.getCaptcha()
        },
        onOpen() {
            this.$nextTick(() => {
                this.$refs['form'].resetFields();
            })
            this.getCaptcha()
        },
        onClose() {
            this.$store.commit('user/setLoginDialogVisible', false)
        },
        onSubmit() {
            this.$refs['form'].validate( async(valid) => {
                if (valid) {
                    const ret = await this.$store.dispatch('user/login', {
                        mobile: this.form.mobile,
                        password: this.form.password,
                        captchaId: this.form.captcha.captchaId,
                        captchaCode: this.form.captcha.captchaCode
                    })
                    if (ret && ret.result) {
                        this.$store.commit('user/setLoginDialogVisible', false)
                        this.$message({
                            type: 'success',
                            message: '登录成功!'
                        });
                    }
                    if (ret && ret.error) {
                        await this.getCaptcha()
                        this.$message({
                            type: 'error',
                            message: ret.error.message,
                        });
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }

}
