import { render, staticRenderFns } from "./cmn-header.vue?vue&type=template&id=658892d3&scoped=true"
import script from "./cmn-header.vue?vue&type=script&lang=js"
export * from "./cmn-header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658892d3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RegisterDialog: require('/ntweb/components/register-dialog.vue').default,ResetDialog: require('/ntweb/components/reset-dialog.vue').default,LoginDialog: require('/ntweb/components/login-dialog.vue').default,CityDialog: require('/ntweb/components/city-dialog.vue').default})
