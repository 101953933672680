import {emptyState, restoreState, tryToRestore} from "@/utils/store";

//1.解决刷新页面store数据丢失问题
//2.解决不同页面store数据同步问题
export default ({ app }) => {

    // 获取到store
    const store = app.store
    // 获取到session中store数据 有就存入
    let state = sessionStorage.getItem('store')
    console.log("+++load+++"+state)
    tryToRestore(store, state)

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
        let state = JSON.stringify(store.state)
        console.log("+++addEventListener:beforeunload:+++"+state)
        sessionStorage.setItem('store',state)
    })
    window.addEventListener('pagehide', () => {
        let state = JSON.stringify(store.state)
        console.log("+++addEventListener:pagehide:+++"+state)
        sessionStorage.setItem('store', state)
    })

    // 恢复store
    window.addEventListener('storage', event => {
        console.log("+++addEventListener:storage:+++"+JSON.stringify(event))
        if(event.key == "store") {
            tryToRestore(app.store,event.newValue)
        }
    })
}
