import realNameStatusJson from "../assets/datas/real-name-status.json"
import {
    STATUS_APPLYING,
    STATUS_APPROVED_WITH_APPLYING,
    STATUS_APPROVED_WITH_REJECTED,
    STATUS_NEW,
    STATUS_OFFLINE_BY_OWNER
} from "@/constants/audit";

export function getRealNameStatuses() {
    return realNameStatusJson.result.filter(function (item) {
        return item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getSettableRealNameStatuses() {
    return realNameStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getAdminSettableRealNameStatuses() {
    return realNameStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_APPLYING
            && item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

//=====

export function getRealNameStatusName(id) {
    for (let i = 0; i < realNameStatusJson.result.length; i++) {
        let obj = realNameStatusJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return "状态未知"
}

export function getRealNameStatusDesc(id) {
    for (let i = 0; i < realNameStatusJson.result.length; i++) {
        let obj = realNameStatusJson.result[i]
        if (obj._id == id) {
            return obj.desc
        }
    }
    return ""
}
