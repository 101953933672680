import moment from 'moment'

export default class CvoBasic {

    constructor(options) {
        this._id = options?._id || 0
        this.cvId = options?.cvId || 0
        this.userName = options?.userName || ""
        this.avatarKey = options?.avatarKey || ""
        this.avatarUrl = options?.avatarUrl || ""
        this.sexId = options?.sexId || 1
        this.birthday = options?.birthday || ""
        this.company = options?.company || ""
        this.jobTitle = options?.jobTitle || ""
        //this.school = options?.school || ""
        this.degreeId = options?.degreeId || ""
        this.graduationDay = options?.graduationDay || ""
        this.mobile = options?.mobile || ""
        this.email = options?.email || ""
        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""
    }

    get isNew() {
        return this._id <= 0
    }

    get age() {
        return moment().diff(this.birthday,'years')
    }

    get workYears() {
        return moment().diff(this.graduationDay,'years')
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            cvId: this.cvId,
            userName: this.userName,
            avatarKey: this.avatarKey,
            avatarUrl: this.avatarUrl,
            sexId: this.sexId,
            birthday: this.birthday,
            company: this.company,
            jobTitle: this.jobTitle,
            // school: this.school,
            degreeId: this.degreeId,
            graduationDay: this.graduationDay,
            mobile: this.mobile,
            email: this.email,
        }
    }

    clone() {
        let options = this.toData()
        return new CvoBasic(options)
    }

    toJSON() {
        return {...this}
    }
}
