import refereeStatusJson from "../assets/datas/referee-status.json"
import {
    STATUS_APPLYING,
    STATUS_APPROVED_WITH_APPLYING,
    STATUS_APPROVED_WITH_REJECTED,
    STATUS_NEW,
    STATUS_OFFLINE_BY_OWNER
} from "@/constants/audit";

export function getRefereeStatuses() {
    return refereeStatusJson.result.filter(function (item) {
        return item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getSettableRefereeStatuses() {
    return refereeStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

export function getAdminSettableRefereeStatuses() {
    return refereeStatusJson.result.filter(function (item) {
        return item._id != STATUS_NEW
            && item._id != STATUS_APPLYING
            && item._id != STATUS_APPROVED_WITH_APPLYING
            && item._id != STATUS_APPROVED_WITH_REJECTED
            && item._id != STATUS_OFFLINE_BY_OWNER
    })
}

//=====

export function getRefereeStatusName(id) {
    for (let i = 0; i < refereeStatusJson.result.length; i++) {
        let obj = refereeStatusJson.result[i]
        if (obj._id == id) {
            return obj.name
        }
    }
    return "状态未知"
}

export function getRefereeStatusDesc(id) {
    for (let i = 0; i < refereeStatusJson.result.length; i++) {
        let obj = refereeStatusJson.result[i]
        if (obj._id == id) {
            return obj.desc
        }
    }
    return ""
}
