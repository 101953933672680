import * as rpc from "@/constants/rpc"
import Company from "@/models/company";
import {STATUS_APPLYING, STATUS_APPROVED} from "@/constants/audit";
import {COMPANY_QUIT, REFEREE_USER_DEL} from "@/constants/rpc";
import {E_NOT_FOUND} from "@/constants/err";


export const state = () => ({
    company: new Company(),
})

export const mutations = {
    setCompany(state, company) {
        state.company = company
    },
}

export const getters = {

}

export const actions = {

    async getCompany(context, _id) {
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.COMPANY_GET, {_id})
        if (ret && ret.result) {
            context.commit('setCompany', new Company(ret.result))
        }
    },
    async quitMyCompany(context) {
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.COMPANY_QUIT)
        if (ret && ret.result) {
            await context.dispatch('handleCompanyChanged', null)
            await this.$axios.rpcMsg(rpc.USER, rpc.REFEREE_USER_DEL, [E_NOT_FOUND])
        }
        return ret
    },
    async searchCompany(context, name) {
        return await this.$axios.rpc(rpc.USER, rpc.COMPANY_SEARCH, {name})
    },
    async applyNewCompany(context, company) {
        const params = company.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.COMPANY_APPLY_NEW, params)
        if (ret && ret.result) {
            company._id = ret.result._id
            company.statusId = STATUS_APPLYING
            await context.dispatch('handleCompanyChanged', company)
        }
        return ret
    },
    async applyUpdateCompany(context, company) {
        const params = company.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.COMPANY_APPLY_UPDATE, params)
        if (ret && ret.result) {
            //await context.dispatch('handleCompanyChanged', company)
            await context.dispatch('getCompany', company._id)
        }
        return ret
    },
    async changeToMyCompany(context, company) {
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.COMPANY_CHANGE_TO_MY, {_id: company._id})
        if (ret && ret.result) {
            await context.dispatch('handleCompanyChanged', company)
        }
        return ret
    },
    async handleCompanyChanged(context, company) {
        if (company) {
            context.commit('user/setUserCompanyId', company._id, {root: true})
            context.commit('setCompany', company)
        } else {
            context.commit('user/setUserCompanyId', 0, {root: true})
            context.commit('setCompany', null)
        }
        //只要公司切换了，内推官就清除
        context.commit('user/setUserRefereeId', 0, {root: true})
        context.commit('referee/setReferee', null, {root: true})
    }
}
