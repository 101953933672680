import Vue from 'vue'

import * as mqtt from "mqtt"
import code from "@/utils/code";
import {
    DEFAULT_MQTT_BROKER_URL,
    MQTT_BROKER_CLEAN,
    MQTT_BROKER_CLIENT_ID,
    MQTT_BROKER_CONNECT_TIMEOUT,
    MQTT_BROKER_KEEP_ALIVE,
    MQTT_BROKER_PASSWORD,
    MQTT_BROKER_RECONNECT_PERIOD,
    MQTT_BROKER_USER,
    MQTT_EVENT_CLOSE,
    MQTT_EVENT_CONNECT,
    MQTT_EVENT_DISCONNECT, MQTT_EVENT_END,
    MQTT_EVENT_ERROR,
    MQTT_EVENT_MESSAGE,
    MQTT_EVENT_OFFLINE,
    MQTT_EVENT_RECONNECT
} from "@/constants/mqtt";


class Mqtt {

    constructor() {
        this.listeners = []

        const options = {
            keepalive: MQTT_BROKER_KEEP_ALIVE, // 默认60秒，设置0为禁用
            clean: MQTT_BROKER_CLEAN, // 设置为false以在脱机时接收QoS 1和2消息
            connectTimeout: MQTT_BROKER_CONNECT_TIMEOUT, // 收到CONNACK之前等待的时间
            reconnectPeriod: MQTT_BROKER_RECONNECT_PERIOD, //设置多长时间进行重新连接 单位毫秒 两次重新连接之间的时间间隔。通过将设置为，禁用自动重新连接0
            clientId: MQTT_BROKER_CLIENT_ID + Math.random().toString(16).substr(2, 8),
            username: MQTT_BROKER_USER,
            password: MQTT_BROKER_PASSWORD,
        }
        const brokerUrl = process.env.MQTT_BROKER_URL || DEFAULT_MQTT_BROKER_URL
        const actualUrl = brokerUrl
        this.client = mqtt.connect(actualUrl, options);
        console.log("====brokerUrl:"+brokerUrl)
        console.log("====actualUrl:"+actualUrl)

        this.client.on(MQTT_EVENT_CONNECT, (packet) => {
            console.log('链接成功')

            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onConnected && code.isFunction(listener.onConnected)) {
                    listener.onConnected()
                }
            })
        });

        this.client.on(MQTT_EVENT_RECONNECT, () => {
            console.log("正在重连.....");
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onReconnect && code.isFunction(listener.onReconnect)) {
                    listener.onReconnect()
                }
            })
        });

        this.client.on(MQTT_EVENT_DISCONNECT, (packet)  => {
            console.log("断开连接.....");
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onDisconnect && code.isFunction(listener.onDisconnect)) {
                    listener.onDisconnect()
                }
            })
        });

        this.client.on(MQTT_EVENT_CLOSE, function () {
            console.log("客户端已断开.....");
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onClosed && code.isFunction(listener.onClosed)) {
                    listener.onClosed()
                }
            })
        });

        this.client.on(MQTT_EVENT_OFFLINE, function () {
            console.log("客户端掉线.....");
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onOffline && code.isFunction(listener.onOffline)) {
                    listener.onOffline()
                }
            })
        });

        //当客户端无法连接或出现错误时触发回调
        this.client.on(MQTT_EVENT_ERROR, (error) => {
            console.log("客户端出现错误....." + error);
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onError && code.isFunction(listener.onError)) {
                    listener.onError()
                }
            })
        });

        this.client.on(MQTT_EVENT_END, () => {
            console.log("客户端end....." );
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onEnd && code.isFunction(listener.onEnd)) {
                    listener.onEnd()
                }
            })
        });

        this.client.on(MQTT_EVENT_MESSAGE, (topic, payload, packet) => {
            console.log(`message:topic=${topic},payload=${payload}`)
            this.listeners?.forEach?.(function (listener, index, array) {
                if (listener && listener.onMessage && code.isFunction(listener.onMessage)) {
                    listener.onMessage(topic, payload, packet)
                }
            })
        });
    }

    // 断开链接
    disconnect() {
        try {
            if (this.client && this.client.connected) {
                this.client.end();
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 重新链接
    reconnect() {
        this.client.reconnect()
    }

    // 关闭
    close() {
        try {
            if (this.client && this.client.connected) {
                this.client.end();
                this.client = null;
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 发布消息
    // opt = { qos: 0, retain: true }
    publish(topic, message, opt) {
        if (this.client && this.client.connected) {
            this.client.publish(topic, message, opt, (error) => {
                if (error) {
                    console.log(error)
                } else {
                    console.log('发布消息成功')
                }
            })
        }
    }

    // 订阅主题
    // opt = {qos: 0}
    subscribe(topic, opt) {
        if (this.client && this.client.connected) {
            this.client.subscribe(topic, opt, function (error, granted) {
                if (error) {
                    console.log(error)
                } else {
                    console.log(topic+"订阅成功")
                }
            })
        }
    }

    // 取消订阅
    unsubscribe(topic) {
        if (this.client && this.client.connected) {
            this.client.unsubscribe(topic, (error) => {
                if (error) {
                    console.log(error)
                } else {
                    console.log('取消订阅成功')
                }
            })
        }
    }

    isConnected() {
        return this.client && this.client.connected
    }

    addListener(l) {
        const index = this.listeners.indexOf(l)
        if (index<0) {
            this.listeners.push(l)
        }
    }

    removeListener(l) {
        this.listeners.splice(l)
        const index = this.listeners.indexOf(l)
        if (index>=0) {
            this.listeners.splice(index, 1)
        }
    }
}


Vue.prototype.$mqtt = new Mqtt()

