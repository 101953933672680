import code from "@/utils/code";
import {KYUAN, YUAN} from "@/constants/money";

export function fen2Yuan(fen) {
    let yuan = null
    if (code.isNumber(fen)) {
        yuan = fen / YUAN
        yuan = code.isFloat(yuan) ? yuan.toFixed(2) : yuan
    }
    return yuan
}

export function yuan2Fen(yuan) {
    let fen = null
    if (code.isNumber(yuan)) {
        fen = yuan * YUAN
    }
    return fen
}

export function fen2KYuan(fen) {
    let kyuan = 0
    if (code.isNumber(fen)) {
        kyuan = fen / KYUAN
        kyuan = code.isFloat(kyuan) ? kyuan.toFixed(1) : kyuan
    }
    return kyuan + "K"
}
