import moment from "moment";

export default class CvoProjectExp {

    constructor(options) {
        this._id = options?._id || 0
        this.cvId = options?.cvId || 0
        this.name = options?.name || ""
        //this.company = options?.company || ""
        this.url = options?.url || ""
        this.from = options?.from || ""
        this.to = options?.to || ""
        this.content = options?.content || ""
        //this.achievement = options?.achievement || ""
        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""

        //新建默认就是编辑状态
        this.isEditing = this.isNew
    }

    get isNew() {
        return this._id <= 0
    }

    get dateRange() {
        return [
            moment(this.from).toDate(),
            moment(this.to).toDate()
        ]
    }

    set dateRange(newValue) {
        this.from = moment(newValue[0]).format("YYYY-MM-DD")
        this.to = moment(newValue[1]).format("YYYY-MM-DD")
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            cvId: this.cvId,
            name: this.name,
            //company: this.company,
            url: this.url,
            from: this.from,
            to: this.to,
            content: this.content,
            //achievement: this.achievement,
        }
    }

    clone() {
        let options = this.toData()
        return new CvoProjectExp(options)
    }

    toJSON() {
        return {...this}
    }
}
