// official definitions
export const E_PARSE        = -32700;
export const E_INVALID_REQ  = -32600;
export const E_NO_METHOD    = -32601;
export const E_BAD_PARAMS   = -32602;
export const E_INTERNAL     = -32603;
export const E_SERVER       = -32000;

// custom definitions from -32000 to -32099
// refer to http://wiki.geekdream.com/Specification/json-rpc_2.0.html
export const E_UNAUTHORIZED        = -32001;
export const E_FORBIDDEN           = -32002;
export const E_FOUND               = -32003;
export const E_NOT_FOUND           = -32004;
export const E_TIMEOUT             = -32005;
export const E_CONFLICT            = -32006;
export const E_LOCKED              = -32007;
export const E_NOT_IMPLEMENTED     = -32008;
export const E_SERVICE_UNAVAILABLE = -32009;
export const E_EXPIRED             = -32010;
export const E_UNKNOWN             = -32099;

export function message(code,obj) {
    switch (code) {
        // official
        case E_PARSE:
            return "数据解析错误";
        case E_INVALID_REQ:
            return "无效的请求";
        case E_NO_METHOD:
            return "没有找到请求方法";
        case E_BAD_PARAMS:
            return "无效的请求参数,请重新检查输入内容";
        case E_INTERNAL:
            return "服务器内部错误";
        case E_SERVER:
            return "服务器错误";

        // custom
        case E_UNAUTHORIZED:
            return "您还没有登录，请登录后重试";
        case E_FORBIDDEN:
            return "你没有权限访问该资源";
        case E_FOUND:
            return (obj||"对象") + "已存在";
        case E_NOT_FOUND:
            return "没有找到" + (obj||"对象");
        case E_TIMEOUT:
            return "请求超时，请稍后重试";
        case E_CONFLICT:
            return "请求冲突，请联系管理员";
        case E_LOCKED:
            return (obj||"") + "已锁定";
        case E_NOT_IMPLEMENTED:
            return "该功能还没有实现";
        case E_SERVICE_UNAVAILABLE:
            return "服务暂不可用，请稍后重试";
        case E_EXPIRED:
            return (obj||"") + "已过期";
        case E_UNKNOWN:
            return "未知错误，请联系管理员";
        default:
            return "未知错误，请联系管理员";
    }
}


