
import {createNamespacedHelpers, mapMutations} from 'vuex'
import user from "@/mixins/user";
//const { mapState, mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
    name: 'CmnHeader',
    mixins: [user],
    props: {
        activeIndex: String,
    },
    data() {
        return {
            city: {
                provinceId: 0,
                provinceCode: '',
                provinceName: '',
                cityId: 0,
                cityCode: '',
                cityName: '',
            },
            activeIndex2: '',
            registerDialogVisible: false,
            resetDialogVisible: false,
            cityDialogVisible: false,
        };
    },
    // computed: {
    //     ...mapState({
    //         user: state => state.user,
    //     }),
    //     ...mapGetters([
    //         'isLogin',
    //         'isLoginUser',
    //         'isLoginAdmin'
    //     ]),
    // },
    mounted() {
        this.setCity()
    },
    watch:{
        city: function (newCity, oldCity) {
            this.$storage.set('city',newCity)
        }
    },
    methods: {
        ...mapMutations({
            setProfileActiveIndex: 'profile/setActiveIndex',
            setAdminActiveIndex: 'admin/setActiveIndex',
        }),
        setCity() {
            const city = this.$storage.get('city')
            if (city) {
                this.city = city;
            } else {
                this.city = {
                    provinceId: 1,
                    provinceCode: '110000000000',
                    provinceName: '北京市',
                    cityId: 1,
                    cityCode: '110100000000',
                    cityName: '北京市',
                }
            }
        },
        handleSelect(key, keyPath) {
            switch (key) {
                case "register":
                    this.registerDialogVisible = true
                    break;
                case "login":
                    this.$store.commit('user/setLoginDialogVisible', true)
                    break;
                case "reset":
                    this.resetDialogVisible = true
                    break;
                case "city":
                    this.cityDialogVisible = true
                    break;
                case "my-jd":
                    this.toMyJd()
                    break;
                case "post-receive":
                    this.toPostReceive()
                    break;
                case "order-receive":
                    this.toOrderReceive()
                    break;
                case "my-cv":
                    this.toMyCv()
                    break;
                case "my-post":
                    this.toMyPost()
                    break;
                case "balance":
                    this.toBalance()
                    break;
                case "income":
                    this.toIncome()
                    break;
                case "withdraw":
                    this.toWithdraw()
                    break;
                case "chat":
                    this.toChat()
                    break;
                case "my":
                    this.toMyProfile()
                    break;
                case "admin":
                    this.toAdmin()
                    break;
                case "logout":
                    this.logoutPrompt()
                    break;
                default:
                    break;
            }
        },
        errorHandler() {
            return true
        },
        toMyJd() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("referee-my-jd")
            this.$router.push("/profiles/my-jd")
        },
        toPostReceive() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("referee-post-receive")
            this.$router.push("/profiles/post-receive")
        },
        toOrderReceive() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("referee-order-receive")
            this.$router.push("/profiles/order-receive")
        },
        toMyCv() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("applicant-cv")
            this.$router.push("/profiles/my-cv")
        },
        toMyPost() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("applicant-post")
            this.$router.push("/profiles/my-post")
        },
        toBalance() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("finance-balance")
            this.$router.push("/profiles/balance")
        },
        toIncome() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("finance-income")
            this.$router.push("/profiles/income")
        },
        toWithdraw() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("finance-withdraw")
            this.$router.push("/profiles/withdraw")
        },
        toChat() {
            if (!this.isLogin) {
                this.toLogin()
                return
            }
            this.setProfileActiveIndex("chat-list")
            this.$router.push("/profiles/chat")
        },
        toMyProfile() {
            this.setProfileActiveIndex("common-account")
            this.$router.push("/profiles/account")
        },
        toAdmin() {
            this.setAdminActiveIndex("audit-user")
            this.$router.push("/admins/audit-user")
        },
        logoutPrompt() {
            this.$confirm('您确定要退出登录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$store.dispatch('user/logout')
                this.$message({
                    type: 'success',
                    message: '已退出登录!'
                });
            }).catch(() => {

            });
        },
    }
}
