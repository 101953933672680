export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const AdminAside = () => import('../../components/admin-aside.vue' /* webpackChunkName: "components/admin-aside" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditCompanyHandler = () => import('../../components/admin-audit-company-handler.vue' /* webpackChunkName: "components/admin-audit-company-handler" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditCompany = () => import('../../components/admin-audit-company.vue' /* webpackChunkName: "components/admin-audit-company" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditRealNameHandler = () => import('../../components/admin-audit-real-name-handler.vue' /* webpackChunkName: "components/admin-audit-real-name-handler" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditRealName = () => import('../../components/admin-audit-real-name.vue' /* webpackChunkName: "components/admin-audit-real-name" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditRefereeHandler = () => import('../../components/admin-audit-referee-handler.vue' /* webpackChunkName: "components/admin-audit-referee-handler" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditReferee = () => import('../../components/admin-audit-referee.vue' /* webpackChunkName: "components/admin-audit-referee" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditUserHandler = () => import('../../components/admin-audit-user-handler.vue' /* webpackChunkName: "components/admin-audit-user-handler" */).then(c => wrapFunctional(c.default || c))
export const AdminAuditWithdraw = () => import('../../components/admin-audit-withdraw.vue' /* webpackChunkName: "components/admin-audit-withdraw" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryCompany = () => import('../../components/admin-query-company.vue' /* webpackChunkName: "components/admin-query-company" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryJd = () => import('../../components/admin-query-jd.vue' /* webpackChunkName: "components/admin-query-jd" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryOrder = () => import('../../components/admin-query-order.vue' /* webpackChunkName: "components/admin-query-order" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryRealName = () => import('../../components/admin-query-real-name.vue' /* webpackChunkName: "components/admin-query-real-name" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryReferee = () => import('../../components/admin-query-referee.vue' /* webpackChunkName: "components/admin-query-referee" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryUser = () => import('../../components/admin-query-user.vue' /* webpackChunkName: "components/admin-query-user" */).then(c => wrapFunctional(c.default || c))
export const AdminQueryWithdraw = () => import('../../components/admin-query-withdraw.vue' /* webpackChunkName: "components/admin-query-withdraw" */).then(c => wrapFunctional(c.default || c))
export const AuditCompanyList = () => import('../../components/audit-company-list.vue' /* webpackChunkName: "components/audit-company-list" */).then(c => wrapFunctional(c.default || c))
export const AuditRealNameList = () => import('../../components/audit-real-name-list.vue' /* webpackChunkName: "components/audit-real-name-list" */).then(c => wrapFunctional(c.default || c))
export const AuditRefereeList = () => import('../../components/audit-referee-list.vue' /* webpackChunkName: "components/audit-referee-list" */).then(c => wrapFunctional(c.default || c))
export const AuditUserList = () => import('../../components/audit-user-list.vue' /* webpackChunkName: "components/audit-user-list" */).then(c => wrapFunctional(c.default || c))
export const AuditWithdrawList = () => import('../../components/audit-withdraw-list.vue' /* webpackChunkName: "components/audit-withdraw-list" */).then(c => wrapFunctional(c.default || c))
export const BankCardAudit = () => import('../../components/bank-card-audit.vue' /* webpackChunkName: "components/bank-card-audit" */).then(c => wrapFunctional(c.default || c))
export const BankCardCard = () => import('../../components/bank-card-card.vue' /* webpackChunkName: "components/bank-card-card" */).then(c => wrapFunctional(c.default || c))
export const BankCardEditor = () => import('../../components/bank-card-editor.vue' /* webpackChunkName: "components/bank-card-editor" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const ChatHisItem = () => import('../../components/chat-his-item.vue' /* webpackChunkName: "components/chat-his-item" */).then(c => wrapFunctional(c.default || c))
export const ChatItem = () => import('../../components/chat-item.vue' /* webpackChunkName: "components/chat-item" */).then(c => wrapFunctional(c.default || c))
export const ChatPage = () => import('../../components/chat-page.vue' /* webpackChunkName: "components/chat-page" */).then(c => wrapFunctional(c.default || c))
export const ChatRoom = () => import('../../components/chat-room.vue' /* webpackChunkName: "components/chat-room" */).then(c => wrapFunctional(c.default || c))
export const ChatSayContent = () => import('../../components/chat-say-content.vue' /* webpackChunkName: "components/chat-say-content" */).then(c => wrapFunctional(c.default || c))
export const ChatSayFile = () => import('../../components/chat-say-file.vue' /* webpackChunkName: "components/chat-say-file" */).then(c => wrapFunctional(c.default || c))
export const ChatSayImage = () => import('../../components/chat-say-image.vue' /* webpackChunkName: "components/chat-say-image" */).then(c => wrapFunctional(c.default || c))
export const ChatSayItem = () => import('../../components/chat-say-item.vue' /* webpackChunkName: "components/chat-say-item" */).then(c => wrapFunctional(c.default || c))
export const ChatSayLeft = () => import('../../components/chat-say-left.vue' /* webpackChunkName: "components/chat-say-left" */).then(c => wrapFunctional(c.default || c))
export const ChatSayLink = () => import('../../components/chat-say-link.vue' /* webpackChunkName: "components/chat-say-link" */).then(c => wrapFunctional(c.default || c))
export const ChatSayMid = () => import('../../components/chat-say-mid.vue' /* webpackChunkName: "components/chat-say-mid" */).then(c => wrapFunctional(c.default || c))
export const ChatSayRight = () => import('../../components/chat-say-right.vue' /* webpackChunkName: "components/chat-say-right" */).then(c => wrapFunctional(c.default || c))
export const ChatUserItem = () => import('../../components/chat-user-item.vue' /* webpackChunkName: "components/chat-user-item" */).then(c => wrapFunctional(c.default || c))
export const CityDialog = () => import('../../components/city-dialog.vue' /* webpackChunkName: "components/city-dialog" */).then(c => wrapFunctional(c.default || c))
export const CmnFooter = () => import('../../components/cmn-footer.vue' /* webpackChunkName: "components/cmn-footer" */).then(c => wrapFunctional(c.default || c))
export const CmnHeader = () => import('../../components/cmn-header.vue' /* webpackChunkName: "components/cmn-header" */).then(c => wrapFunctional(c.default || c))
export const CmnStatusHandler = () => import('../../components/cmn-status-handler.vue' /* webpackChunkName: "components/cmn-status-handler" */).then(c => wrapFunctional(c.default || c))
export const CompanyAudit = () => import('../../components/company-audit.vue' /* webpackChunkName: "components/company-audit" */).then(c => wrapFunctional(c.default || c))
export const CompanyCard = () => import('../../components/company-card.vue' /* webpackChunkName: "components/company-card" */).then(c => wrapFunctional(c.default || c))
export const CompanyEditor = () => import('../../components/company-editor.vue' /* webpackChunkName: "components/company-editor" */).then(c => wrapFunctional(c.default || c))
export const CompanyItem = () => import('../../components/company-item.vue' /* webpackChunkName: "components/company-item" */).then(c => wrapFunctional(c.default || c))
export const CompanySearch = () => import('../../components/company-search.vue' /* webpackChunkName: "components/company-search" */).then(c => wrapFunctional(c.default || c))
export const CvEdAdvEdit = () => import('../../components/cv-ed-adv-edit.vue' /* webpackChunkName: "components/cv-ed-adv-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdAdvRead = () => import('../../components/cv-ed-adv-read.vue' /* webpackChunkName: "components/cv-ed-adv-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdAdvTitle = () => import('../../components/cv-ed-adv-title.vue' /* webpackChunkName: "components/cv-ed-adv-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdAdv = () => import('../../components/cv-ed-adv.vue' /* webpackChunkName: "components/cv-ed-adv" */).then(c => wrapFunctional(c.default || c))
export const CvEdAttachEdit = () => import('../../components/cv-ed-attach-edit.vue' /* webpackChunkName: "components/cv-ed-attach-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdAttachRead = () => import('../../components/cv-ed-attach-read.vue' /* webpackChunkName: "components/cv-ed-attach-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdAttachTitle = () => import('../../components/cv-ed-attach-title.vue' /* webpackChunkName: "components/cv-ed-attach-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdAttach = () => import('../../components/cv-ed-attach.vue' /* webpackChunkName: "components/cv-ed-attach" */).then(c => wrapFunctional(c.default || c))
export const CvEdBasicEdit = () => import('../../components/cv-ed-basic-edit.vue' /* webpackChunkName: "components/cv-ed-basic-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdBasicRead = () => import('../../components/cv-ed-basic-read.vue' /* webpackChunkName: "components/cv-ed-basic-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdBasic = () => import('../../components/cv-ed-basic.vue' /* webpackChunkName: "components/cv-ed-basic" */).then(c => wrapFunctional(c.default || c))
export const CvEdEduEdit = () => import('../../components/cv-ed-edu-edit.vue' /* webpackChunkName: "components/cv-ed-edu-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdEduList = () => import('../../components/cv-ed-edu-list.vue' /* webpackChunkName: "components/cv-ed-edu-list" */).then(c => wrapFunctional(c.default || c))
export const CvEdEduRead = () => import('../../components/cv-ed-edu-read.vue' /* webpackChunkName: "components/cv-ed-edu-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdEduTitle = () => import('../../components/cv-ed-edu-title.vue' /* webpackChunkName: "components/cv-ed-edu-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdEdu = () => import('../../components/cv-ed-edu.vue' /* webpackChunkName: "components/cv-ed-edu" */).then(c => wrapFunctional(c.default || c))
export const CvEdHint = () => import('../../components/cv-ed-hint.vue' /* webpackChunkName: "components/cv-ed-hint" */).then(c => wrapFunctional(c.default || c))
export const CvEdIntentEdit = () => import('../../components/cv-ed-intent-edit.vue' /* webpackChunkName: "components/cv-ed-intent-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdIntentList = () => import('../../components/cv-ed-intent-list.vue' /* webpackChunkName: "components/cv-ed-intent-list" */).then(c => wrapFunctional(c.default || c))
export const CvEdIntentRead = () => import('../../components/cv-ed-intent-read.vue' /* webpackChunkName: "components/cv-ed-intent-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdIntentTitle = () => import('../../components/cv-ed-intent-title.vue' /* webpackChunkName: "components/cv-ed-intent-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdIntent = () => import('../../components/cv-ed-intent.vue' /* webpackChunkName: "components/cv-ed-intent" */).then(c => wrapFunctional(c.default || c))
export const CvEdNameEdit = () => import('../../components/cv-ed-name-edit.vue' /* webpackChunkName: "components/cv-ed-name-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdNameRead = () => import('../../components/cv-ed-name-read.vue' /* webpackChunkName: "components/cv-ed-name-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdName = () => import('../../components/cv-ed-name.vue' /* webpackChunkName: "components/cv-ed-name" */).then(c => wrapFunctional(c.default || c))
export const CvEdProjectExpEdit = () => import('../../components/cv-ed-project-exp-edit.vue' /* webpackChunkName: "components/cv-ed-project-exp-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdProjectExpList = () => import('../../components/cv-ed-project-exp-list.vue' /* webpackChunkName: "components/cv-ed-project-exp-list" */).then(c => wrapFunctional(c.default || c))
export const CvEdProjectExpRead = () => import('../../components/cv-ed-project-exp-read.vue' /* webpackChunkName: "components/cv-ed-project-exp-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdProjectExpTitle = () => import('../../components/cv-ed-project-exp-title.vue' /* webpackChunkName: "components/cv-ed-project-exp-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdProjectExp = () => import('../../components/cv-ed-project-exp.vue' /* webpackChunkName: "components/cv-ed-project-exp" */).then(c => wrapFunctional(c.default || c))
export const CvEdSocialEdit = () => import('../../components/cv-ed-social-edit.vue' /* webpackChunkName: "components/cv-ed-social-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdSocialList = () => import('../../components/cv-ed-social-list.vue' /* webpackChunkName: "components/cv-ed-social-list" */).then(c => wrapFunctional(c.default || c))
export const CvEdSocialRead = () => import('../../components/cv-ed-social-read.vue' /* webpackChunkName: "components/cv-ed-social-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdSocialTitle = () => import('../../components/cv-ed-social-title.vue' /* webpackChunkName: "components/cv-ed-social-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdSocial = () => import('../../components/cv-ed-social.vue' /* webpackChunkName: "components/cv-ed-social" */).then(c => wrapFunctional(c.default || c))
export const CvEdTitle = () => import('../../components/cv-ed-title.vue' /* webpackChunkName: "components/cv-ed-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdWorkExpEdit = () => import('../../components/cv-ed-work-exp-edit.vue' /* webpackChunkName: "components/cv-ed-work-exp-edit" */).then(c => wrapFunctional(c.default || c))
export const CvEdWorkExpList = () => import('../../components/cv-ed-work-exp-list.vue' /* webpackChunkName: "components/cv-ed-work-exp-list" */).then(c => wrapFunctional(c.default || c))
export const CvEdWorkExpRead = () => import('../../components/cv-ed-work-exp-read.vue' /* webpackChunkName: "components/cv-ed-work-exp-read" */).then(c => wrapFunctional(c.default || c))
export const CvEdWorkExpTitle = () => import('../../components/cv-ed-work-exp-title.vue' /* webpackChunkName: "components/cv-ed-work-exp-title" */).then(c => wrapFunctional(c.default || c))
export const CvEdWorkExp = () => import('../../components/cv-ed-work-exp.vue' /* webpackChunkName: "components/cv-ed-work-exp" */).then(c => wrapFunctional(c.default || c))
export const CvEditor = () => import('../../components/cv-editor.vue' /* webpackChunkName: "components/cv-editor" */).then(c => wrapFunctional(c.default || c))
export const CvItem = () => import('../../components/cv-item.vue' /* webpackChunkName: "components/cv-item" */).then(c => wrapFunctional(c.default || c))
export const CvList = () => import('../../components/cv-list.vue' /* webpackChunkName: "components/cv-list" */).then(c => wrapFunctional(c.default || c))
export const CvSelectorItem = () => import('../../components/cv-selector-item.vue' /* webpackChunkName: "components/cv-selector-item" */).then(c => wrapFunctional(c.default || c))
export const CvSelectorList = () => import('../../components/cv-selector-list.vue' /* webpackChunkName: "components/cv-selector-list" */).then(c => wrapFunctional(c.default || c))
export const CvStatusHandler = () => import('../../components/cv-status-handler.vue' /* webpackChunkName: "components/cv-status-handler" */).then(c => wrapFunctional(c.default || c))
export const CvUploader = () => import('../../components/cv-uploader.vue' /* webpackChunkName: "components/cv-uploader" */).then(c => wrapFunctional(c.default || c))
export const CvoRdAdv = () => import('../../components/cvo-rd-adv.vue' /* webpackChunkName: "components/cvo-rd-adv" */).then(c => wrapFunctional(c.default || c))
export const CvoRdAttach = () => import('../../components/cvo-rd-attach.vue' /* webpackChunkName: "components/cvo-rd-attach" */).then(c => wrapFunctional(c.default || c))
export const CvoRdBasic = () => import('../../components/cvo-rd-basic.vue' /* webpackChunkName: "components/cvo-rd-basic" */).then(c => wrapFunctional(c.default || c))
export const CvoRdEduList = () => import('../../components/cvo-rd-edu-list.vue' /* webpackChunkName: "components/cvo-rd-edu-list" */).then(c => wrapFunctional(c.default || c))
export const CvoRdEdu = () => import('../../components/cvo-rd-edu.vue' /* webpackChunkName: "components/cvo-rd-edu" */).then(c => wrapFunctional(c.default || c))
export const CvoRdHint = () => import('../../components/cvo-rd-hint.vue' /* webpackChunkName: "components/cvo-rd-hint" */).then(c => wrapFunctional(c.default || c))
export const CvoRdIntentList = () => import('../../components/cvo-rd-intent-list.vue' /* webpackChunkName: "components/cvo-rd-intent-list" */).then(c => wrapFunctional(c.default || c))
export const CvoRdIntent = () => import('../../components/cvo-rd-intent.vue' /* webpackChunkName: "components/cvo-rd-intent" */).then(c => wrapFunctional(c.default || c))
export const CvoRdName = () => import('../../components/cvo-rd-name.vue' /* webpackChunkName: "components/cvo-rd-name" */).then(c => wrapFunctional(c.default || c))
export const CvoRdProjectExpList = () => import('../../components/cvo-rd-project-exp-list.vue' /* webpackChunkName: "components/cvo-rd-project-exp-list" */).then(c => wrapFunctional(c.default || c))
export const CvoRdProjectExp = () => import('../../components/cvo-rd-project-exp.vue' /* webpackChunkName: "components/cvo-rd-project-exp" */).then(c => wrapFunctional(c.default || c))
export const CvoRdSocialList = () => import('../../components/cvo-rd-social-list.vue' /* webpackChunkName: "components/cvo-rd-social-list" */).then(c => wrapFunctional(c.default || c))
export const CvoRdSocial = () => import('../../components/cvo-rd-social.vue' /* webpackChunkName: "components/cvo-rd-social" */).then(c => wrapFunctional(c.default || c))
export const CvoRdTitle = () => import('../../components/cvo-rd-title.vue' /* webpackChunkName: "components/cvo-rd-title" */).then(c => wrapFunctional(c.default || c))
export const CvoRdWorkExpList = () => import('../../components/cvo-rd-work-exp-list.vue' /* webpackChunkName: "components/cvo-rd-work-exp-list" */).then(c => wrapFunctional(c.default || c))
export const CvoRdWorkExp = () => import('../../components/cvo-rd-work-exp.vue' /* webpackChunkName: "components/cvo-rd-work-exp" */).then(c => wrapFunctional(c.default || c))
export const CvoReader = () => import('../../components/cvo-reader.vue' /* webpackChunkName: "components/cvo-reader" */).then(c => wrapFunctional(c.default || c))
export const Emoji = () => import('../../components/emoji.vue' /* webpackChunkName: "components/emoji" */).then(c => wrapFunctional(c.default || c))
export const EmptySsr = () => import('../../components/empty-ssr.vue' /* webpackChunkName: "components/empty-ssr" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const ExpenseHandler = () => import('../../components/expense-handler.vue' /* webpackChunkName: "components/expense-handler" */).then(c => wrapFunctional(c.default || c))
export const ExpenseItem = () => import('../../components/expense-item.vue' /* webpackChunkName: "components/expense-item" */).then(c => wrapFunctional(c.default || c))
export const HomeTabBar = () => import('../../components/home-tab-bar.vue' /* webpackChunkName: "components/home-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const IncomeHandler = () => import('../../components/income-handler.vue' /* webpackChunkName: "components/income-handler" */).then(c => wrapFunctional(c.default || c))
export const IncomeItem = () => import('../../components/income-item.vue' /* webpackChunkName: "components/income-item" */).then(c => wrapFunctional(c.default || c))
export const JdComment = () => import('../../components/jd-comment.vue' /* webpackChunkName: "components/jd-comment" */).then(c => wrapFunctional(c.default || c))
export const JdCompany = () => import('../../components/jd-company.vue' /* webpackChunkName: "components/jd-company" */).then(c => wrapFunctional(c.default || c))
export const JdDetailBody = () => import('../../components/jd-detail-body.vue' /* webpackChunkName: "components/jd-detail-body" */).then(c => wrapFunctional(c.default || c))
export const JdDetailHeader = () => import('../../components/jd-detail-header.vue' /* webpackChunkName: "components/jd-detail-header" */).then(c => wrapFunctional(c.default || c))
export const JdDetail = () => import('../../components/jd-detail.vue' /* webpackChunkName: "components/jd-detail" */).then(c => wrapFunctional(c.default || c))
export const JdEditor = () => import('../../components/jd-editor.vue' /* webpackChunkName: "components/jd-editor" */).then(c => wrapFunctional(c.default || c))
export const JdFilter = () => import('../../components/jd-filter.vue' /* webpackChunkName: "components/jd-filter" */).then(c => wrapFunctional(c.default || c))
export const JdItem = () => import('../../components/jd-item.vue' /* webpackChunkName: "components/jd-item" */).then(c => wrapFunctional(c.default || c))
export const JdList = () => import('../../components/jd-list.vue' /* webpackChunkName: "components/jd-list" */).then(c => wrapFunctional(c.default || c))
export const JdOwner = () => import('../../components/jd-owner.vue' /* webpackChunkName: "components/jd-owner" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipAppliedItemHandler = () => import('../../components/jd-ownership-applied-item-handler.vue' /* webpackChunkName: "components/jd-ownership-applied-item-handler" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipAppliedItem = () => import('../../components/jd-ownership-applied-item.vue' /* webpackChunkName: "components/jd-ownership-applied-item" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipApplied = () => import('../../components/jd-ownership-applied.vue' /* webpackChunkName: "components/jd-ownership-applied" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipReceivedItemHandler = () => import('../../components/jd-ownership-received-item-handler.vue' /* webpackChunkName: "components/jd-ownership-received-item-handler" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipReceivedItem = () => import('../../components/jd-ownership-received-item.vue' /* webpackChunkName: "components/jd-ownership-received-item" */).then(c => wrapFunctional(c.default || c))
export const JdOwnershipReceived = () => import('../../components/jd-ownership-received.vue' /* webpackChunkName: "components/jd-ownership-received" */).then(c => wrapFunctional(c.default || c))
export const JdRecomList = () => import('../../components/jd-recom-list.vue' /* webpackChunkName: "components/jd-recom-list" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeAppliedItemHandler = () => import('../../components/jd-referee-applied-item-handler.vue' /* webpackChunkName: "components/jd-referee-applied-item-handler" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeAppliedItem = () => import('../../components/jd-referee-applied-item.vue' /* webpackChunkName: "components/jd-referee-applied-item" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeApplied = () => import('../../components/jd-referee-applied.vue' /* webpackChunkName: "components/jd-referee-applied" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeItem = () => import('../../components/jd-referee-item.vue' /* webpackChunkName: "components/jd-referee-item" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeList = () => import('../../components/jd-referee-list.vue' /* webpackChunkName: "components/jd-referee-list" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeReceivedItemHandler = () => import('../../components/jd-referee-received-item-handler.vue' /* webpackChunkName: "components/jd-referee-received-item-handler" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeReceivedItem = () => import('../../components/jd-referee-received-item.vue' /* webpackChunkName: "components/jd-referee-received-item" */).then(c => wrapFunctional(c.default || c))
export const JdRefereeReceived = () => import('../../components/jd-referee-received.vue' /* webpackChunkName: "components/jd-referee-received" */).then(c => wrapFunctional(c.default || c))
export const JdUser = () => import('../../components/jd-user.vue' /* webpackChunkName: "components/jd-user" */).then(c => wrapFunctional(c.default || c))
export const JobAside = () => import('../../components/job-aside.vue' /* webpackChunkName: "components/job-aside" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog = () => import('../../components/login-dialog.vue' /* webpackChunkName: "components/login-dialog" */).then(c => wrapFunctional(c.default || c))
export const MyInvolvedJd = () => import('../../components/my-involved-jd.vue' /* webpackChunkName: "components/my-involved-jd" */).then(c => wrapFunctional(c.default || c))
export const MyOwnedJd = () => import('../../components/my-owned-jd.vue' /* webpackChunkName: "components/my-owned-jd" */).then(c => wrapFunctional(c.default || c))
export const OpJdTimeByCompanyId = () => import('../../components/op-jd-time-by-company-id.vue' /* webpackChunkName: "components/op-jd-time-by-company-id" */).then(c => wrapFunctional(c.default || c))
export const OpJdTimeByJdId = () => import('../../components/op-jd-time-by-jd-id.vue' /* webpackChunkName: "components/op-jd-time-by-jd-id" */).then(c => wrapFunctional(c.default || c))
export const OpJdTimeByUserId = () => import('../../components/op-jd-time-by-user-id.vue' /* webpackChunkName: "components/op-jd-time-by-user-id" */).then(c => wrapFunctional(c.default || c))
export const OpSensitiveAddition = () => import('../../components/op-sensitive-addition.vue' /* webpackChunkName: "components/op-sensitive-addition" */).then(c => wrapFunctional(c.default || c))
export const OpSensitiveBase = () => import('../../components/op-sensitive-base.vue' /* webpackChunkName: "components/op-sensitive-base" */).then(c => wrapFunctional(c.default || c))
export const OpSensitiveShield = () => import('../../components/op-sensitive-shield.vue' /* webpackChunkName: "components/op-sensitive-shield" */).then(c => wrapFunctional(c.default || c))
export const OpSensitiveTest = () => import('../../components/op-sensitive-test.vue' /* webpackChunkName: "components/op-sensitive-test" */).then(c => wrapFunctional(c.default || c))
export const OpSensitiveWordList = () => import('../../components/op-sensitive-word-list.vue' /* webpackChunkName: "components/op-sensitive-word-list" */).then(c => wrapFunctional(c.default || c))
export const OrderStatusHandler = () => import('../../components/order-status-handler.vue' /* webpackChunkName: "components/order-status-handler" */).then(c => wrapFunctional(c.default || c))
export const OrderStepEditor = () => import('../../components/order-step-editor.vue' /* webpackChunkName: "components/order-step-editor" */).then(c => wrapFunctional(c.default || c))
export const OrderStepMeetingEditor = () => import('../../components/order-step-meeting-editor.vue' /* webpackChunkName: "components/order-step-meeting-editor" */).then(c => wrapFunctional(c.default || c))
export const OrderStepMeetingRequestEditor = () => import('../../components/order-step-meeting-request-editor.vue' /* webpackChunkName: "components/order-step-meeting-request-editor" */).then(c => wrapFunctional(c.default || c))
export const PostItem = () => import('../../components/post-item.vue' /* webpackChunkName: "components/post-item" */).then(c => wrapFunctional(c.default || c))
export const PostReceiveItem = () => import('../../components/post-receive-item.vue' /* webpackChunkName: "components/post-receive-item" */).then(c => wrapFunctional(c.default || c))
export const PostRefereeHandler = () => import('../../components/post-referee-handler.vue' /* webpackChunkName: "components/post-referee-handler" */).then(c => wrapFunctional(c.default || c))
export const PostUserHandler = () => import('../../components/post-user-handler.vue' /* webpackChunkName: "components/post-user-handler" */).then(c => wrapFunctional(c.default || c))
export const ProfileAside = () => import('../../components/profile-aside.vue' /* webpackChunkName: "components/profile-aside" */).then(c => wrapFunctional(c.default || c))
export const QrPayHandler = () => import('../../components/qr-pay-handler.vue' /* webpackChunkName: "components/qr-pay-handler" */).then(c => wrapFunctional(c.default || c))
export const RealNameAudit = () => import('../../components/real-name-audit.vue' /* webpackChunkName: "components/real-name-audit" */).then(c => wrapFunctional(c.default || c))
export const RealNameCard = () => import('../../components/real-name-card.vue' /* webpackChunkName: "components/real-name-card" */).then(c => wrapFunctional(c.default || c))
export const RealNameEditor = () => import('../../components/real-name-editor.vue' /* webpackChunkName: "components/real-name-editor" */).then(c => wrapFunctional(c.default || c))
export const RealNameItem = () => import('../../components/real-name-item.vue' /* webpackChunkName: "components/real-name-item" */).then(c => wrapFunctional(c.default || c))
export const ReceiveItem = () => import('../../components/receive-item.vue' /* webpackChunkName: "components/receive-item" */).then(c => wrapFunctional(c.default || c))
export const RefereeAudit = () => import('../../components/referee-audit.vue' /* webpackChunkName: "components/referee-audit" */).then(c => wrapFunctional(c.default || c))
export const RefereeCard = () => import('../../components/referee-card.vue' /* webpackChunkName: "components/referee-card" */).then(c => wrapFunctional(c.default || c))
export const RefereeEditor = () => import('../../components/referee-editor.vue' /* webpackChunkName: "components/referee-editor" */).then(c => wrapFunctional(c.default || c))
export const RefereeItem = () => import('../../components/referee-item.vue' /* webpackChunkName: "components/referee-item" */).then(c => wrapFunctional(c.default || c))
export const RefundHandler = () => import('../../components/refund-handler.vue' /* webpackChunkName: "components/refund-handler" */).then(c => wrapFunctional(c.default || c))
export const RefundItem = () => import('../../components/refund-item.vue' /* webpackChunkName: "components/refund-item" */).then(c => wrapFunctional(c.default || c))
export const RegisterDialog = () => import('../../components/register-dialog.vue' /* webpackChunkName: "components/register-dialog" */).then(c => wrapFunctional(c.default || c))
export const ResetDialog = () => import('../../components/reset-dialog.vue' /* webpackChunkName: "components/reset-dialog" */).then(c => wrapFunctional(c.default || c))
export const SearchAside = () => import('../../components/search-aside.vue' /* webpackChunkName: "components/search-aside" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search-bar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SkeletonJdItem = () => import('../../components/skeleton-jd-item.vue' /* webpackChunkName: "components/skeleton-jd-item" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTabBar = () => import('../../components/skeleton-tab-bar.vue' /* webpackChunkName: "components/skeleton-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const Ta = () => import('../../components/ta.vue' /* webpackChunkName: "components/ta" */).then(c => wrapFunctional(c.default || c))
export const UserCard = () => import('../../components/user-card.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c))
export const UserItem = () => import('../../components/user-item.vue' /* webpackChunkName: "components/user-item" */).then(c => wrapFunctional(c.default || c))
export const WithdrawCreateHandler = () => import('../../components/withdraw-create-handler.vue' /* webpackChunkName: "components/withdraw-create-handler" */).then(c => wrapFunctional(c.default || c))
export const WithdrawHandler = () => import('../../components/withdraw-handler.vue' /* webpackChunkName: "components/withdraw-handler" */).then(c => wrapFunctional(c.default || c))
export const WithdrawItem = () => import('../../components/withdraw-item.vue' /* webpackChunkName: "components/withdraw-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
