import {mapState, mapGetters} from "vuex";

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        ...mapGetters('user',[
            'isLogin',
            'isManualLogin',
            'isLoginUser',
            'isLoginAdmin',
            'hasCompanyId',
            'hasRefereeId',
            'hasRealNameId',
            'companyId',
            'refereeId',
            'realNameId',
        ]),
        headers() {
            const userToken = this.$cookies.get('access_token')
            return {Authorization: "Bearer " + userToken}
        },
    },
    watch: {
        isManualLogin(newVal) {
            if (newVal) {
                this.manualLogin()
            }
        }
    },
    methods: {
        manualLogin() {
            console.log("=== from mixin ===")
        },
        toLogin() {
            this.$store.commit('user/setLoginDialogVisible', true)
        },
        toHome() {
            this.$router.push("/")
        }
    }
}
