import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.prototype.$copy = function (value) {
    this.$copyText(
        `${value}`
    ).then(res => {
            //这是element的Message 消息提示组件
            this.$message.success("复制成功");
        },
        err => {
            this.$message.error("复制失败");
        }
    );
}

