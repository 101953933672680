import Company from "@/models/company";
import Referee from "@/models/referee";
import RealName from "@/models/real-name";
import Cvo from "@/models/cvo";
import {defaultUser} from "@/constants/user";

function restoreUser(stateObj) {

}

function restoreCompany(stateObj) {
    let company = stateObj?.company?.company
    if (company) {
        stateObj.company.company = new Company(company)
    }
}

function restoreReferee(stateObj) {
    let referee = stateObj?.referee?.referee
    if (referee) {
        stateObj.referee.referee = new Referee(referee)
    }
}

function restoreRealName(stateObj) {
    let realName = stateObj && stateObj["real-name"]?.realName
    if (realName) {
        stateObj["real-name"].realName = new RealName(realName)
    }
}

function restoreCvo(stateObj) {
    let cvo = stateObj?.cvo?.cvo
    if (cvo) {
        stateObj.cvo.cvo = new Cvo(cvo)
    }
}

export function restoreState(stateObj) {
    restoreUser(stateObj)
    restoreCompany(stateObj)
    restoreReferee(stateObj)
    restoreRealName(stateObj)
    restoreCvo(stateObj)
    return stateObj
}

export function emptyState() {
    return {
        user: {
            user: defaultUser,
            accessToken: null,
            refreshToken: null,
            loginDialogVisible: false,
        },
        company: {
            company: new Company()
        },
        referee: {
            referee: new Referee()
        },
        'real-name': {
            'real-name': new RealName()
        },
        cvo: {
            cvo: new Cvo()
        },
        admin: {
            activeIndex: ""
        },
        profile: {
            activeIndex: ""
        },
    }
}

export function tryToRestore(store, state) {
    if (state) {
        let newStateObj = restoreState(JSON.parse(state))
        newStateObj.user.loginDialogVisible = false
        store.replaceState(Object.assign({}, store.state, newStateObj))
    } else {
        store.replaceState(Object.assign({},emptyState()))
    }
}
