import {
    STATUS_APPLYING, STATUS_APPROVED,
    STATUS_NEW, STATUS_OFFLINE_BY_ADMIN,
    STATUS_OFFLINE_BY_OWNER, STATUS_REJECTED
} from "@/constants/audit";
import {getRefereeStatusName} from "@/utils/referee";

export default class Referee {

    constructor(options) {
        this._id = options?._id || 0
        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""

        this.userId = options?.userId || 0
        this.companyId = options?.companyId || 0
        this.department = options?.department || ""
        this.jobTitle = options?.jobTitle || ""
        this.companyEmail = options?.companyEmail || ""
        this.emailAuthCode = options?.emailAuthCode || ""
        this.introduction = options?.introduction || ""

        this.statusId = options?.statusId || 0
        this.statusText = options?.statusText || "-"
        this.auditExtra = options?.auditExtra || ""
    }

    get statusShort() {
        //return refereeStatusShort(this.statusId)
        return getRefereeStatusName(this.statusId)
    }

    get isApproved() {
        return this.statusId == STATUS_APPROVED
    }

    get isApplying() {
        return this.statusId == STATUS_APPLYING
    }

    get isRejected() {
        return this.statusId == STATUS_REJECTED
    }

    get isOfflineByAdmin() {
        return this.statusId == STATUS_OFFLINE_BY_ADMIN
    }

    get isAuditNothing() {
        return this.statusId == STATUS_NEW
            || this.statusId == STATUS_OFFLINE_BY_OWNER
    }

    get isNew() {
        return this._id <= 0
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = this.statusId
        vo.statusText = this.statusText
        vo.auditExtra = this.auditExtra
        return vo
    }

    toCreateData() {
        let vo = this.commonData()
        vo.statusId = STATUS_APPLYING
        vo.emailAuthCode= this.emailAuthCode
        return vo
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.emailAuthCode= this.emailAuthCode
        vo.statusId = STATUS_APPLYING
        return vo
    }

    commonData() {
        return {
            userId: this.userId,
            companyId: this.companyId,
            department: this.department,
            jobTitle: this.jobTitle,
            companyEmail: this.companyEmail,
            introduction: this.introduction,
        }
    }

    clone() {
        let options = this.toData()
        return new Referee(options)
    }

    toJSON () {
        return { ...this }
    }
}
