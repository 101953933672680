import code from '@/utils/code'
import * as err from '@/constants/err'
import {Message} from 'element-ui'

export default function ({$axios, app, store}) {

    app.$message = Message

    $axios.onRequest((config) => {
        config.headers.common['X-Client'] = 'ntweb'
        if (config && config.data && config.data.jsonrpc) {
            config.headers.post['Content-Type'] = 'application/json'
        } else {
            config.headers.post['Content-Type'] = 'multipart/form-data'
            //config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        const userToken = app.$cookies.get('access_token') //refresh_token
        if (userToken) {
            config.headers.common['Authorization'] = "Bearer " + userToken
        }
        config.transformRequest = [
            function (data) {
                data = JSON.stringify(data)
                return data
            },
        ]
    })

    $axios.onResponse((response) => {
        if (response.status !== 200) {
            return Promise.reject(response)
        }

        const jsonResult = response.data
        if (jsonResult) {
            // JsonRpc result
            if (jsonResult.jsonrpc) {
                if (jsonResult.error) {
                    return Promise.reject(jsonResult.error)
                } else {
                    return Promise.resolve(jsonResult.result || "")
                }
            }
        }
    })

    $axios.rpc = async function (url, method, params) {
        let ret = {result:null,error:null}
        try {
            const req = {
                jsonrpc: "2.0",
                method: method || "",
                params: params ? [].concat(params) : [],
                id: code.uuid(),
            }
            ret.result = await $axios.post(url, req)
        } catch(e) {
            ret.error = e
            if (e.code === err.E_UNAUTHORIZED) {
                setTimeout(async ()=> {
                    await store.dispatch('user/clear')
                },100)
            }
            console.error(JSON.stringify({url,method,params,e}))
        }
        return ret
    }

    $axios.rpcMsg = async function (url, method, params, ignoredErrs) {
        let ret = await $axios.rpc(url, method, params)
        if (ret && ret.error) {

            // 实际错误和指定忽略的错误不一致才提示
            if (!(ignoredErrs && Array.isArray(ignoredErrs)
                && ignoredErrs.includes(ret.error.code))) {

                const msg = ret.error.code == err.E_UNAUTHORIZED
                    ? "登录已过期，请您重新登录"
                    : ret.error.message
                app.$message({
                    type: 'error',
                    message: msg,
                });
            }

        }
        return ret
    }

    $axios.uploadFile = async function (url, file) {
        let ret = null
        try {
            const formData = new FormData();
            formData.append("file",file);
            const axios = $axios.create({withCredentials: false})
            const token = "Bearer " + app.$cookies.get('access_token') //refresh_token
            ret = await axios.post(url, formData, {
                headers: {
                    'X-Client': 'ntweb',
                    'Authorization': token,
                    'Content-type' : 'multipart/form-data'
                }
            })
        } catch(e) {
            console.log(e)
        }
        return ret
    }

}
