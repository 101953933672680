import Vue from 'vue'
import code from "@/utils/code"

function get(key) {
    let data = localStorage.getItem(key);
    if (!data) {
        return null
    }

    const { value, time, maxAgeInSeconds } = JSON.parse(data)
    if (code.isNumber(maxAgeInSeconds)) {
        if (time + maxAgeInSeconds*1000 < Date.now()) {
            localStorage.removeItem(key)
            return undefined
        }
    }
    return value
}

function set(key, val, maxAgeInSeconds) {
    const data = {
        value : val,
        time: Date.now(),
        maxAgeInSeconds : maxAgeInSeconds,
    }
    localStorage.setItem(key,JSON.stringify(data));
}

function del(key) {
    localStorage.removeItem(key)
}

function arrayAdd(key,val,maxLen) {
    if (!val) {
        return
    }
    let value = get(key)
    if (value) {
        if (Array.isArray(value)) {
            value.push(val)
        } else {
            value = [value,val]
        }
        if (code.isNumber(maxLen) && maxLen>0 && value.length>maxLen) {
            value = value.slice(value.length-maxLen)
        }
    } else {
        value = [val]
    }
    set(key,value)
}

function array(key) {
    let value = get(key)
    if (!value) {
        return []
    } else if (!Array.isArray(value)) {
        return [value]
    } else {
        return value
    }
}

function clear() {
    localStorage.clear()
}

function length() {
    localStorage.length
}

Vue.prototype.$storage = {
    get,
    set,
    del,
    arrayAdd,
    array,
    clear,
    length,
}

