

import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'ProfileAside',
    data() {
        return {
            defaultOpened: ["common", "applicant", "referee", "finance", "chat"],
        };
    },
    computed: {
        ...mapGetters({
            activeIndex: 'profile/activeIndex'
        }),
    },
    methods: {
        ...mapMutations({
            setActiveIndex: 'profile/setActiveIndex'
        }),
        handleOpen(key, keyPath) {
            console.log("==handleOpen", key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log("==handleClose", key, keyPath);
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.setActiveIndex(key)
            switch (key) {
                case "common-account":
                    this.$router.push("/profiles/account");
                    break;
                case "common-jd-fav":
                    this.$router.push("/profiles/jd-fav");
                    break;
                case "common-friend-fav":
                    this.$router.push("/profiles/friend-fav");
                    break;
                case "applicant-cv":
                    this.$router.push("/profiles/my-cv");
                    break;
                case "applicant-post":
                    this.$router.push("/profiles/my-post");
                    break;
                case "applicant-job-fav":
                    this.$router.push("/profiles/job-fav");
                    break;
                case "referee-company":
                    this.$router.push("/profiles/company");
                    break;
                case "referee-referee":
                    this.$router.push("/profiles/referee");
                    break;
                case "referee-jd-referee":
                    this.$router.push("/profiles/jd-referee");
                    break
                case "referee-jd-ownership":
                    this.$router.push("/profiles/jd-ownership");
                    break
                case "referee-auth":
                    this.$router.push("/profiles/auth");
                    break;
                case "referee-my-jd":
                    this.$router.push("/profiles/my-jd");
                    break;
                case "referee-service":
                    this.$router.push("/profiles/service");
                    break;
                case "referee-post-receive":
                    this.$router.push("/profiles/post-receive");
                    break;
                case "referee-order-receive":
                    this.$router.push("/profiles/order-receive");
                    break;
                case "referee-cv-fav":
                    this.$router.push("/profiles/cv-fav");
                    break;
                case "finance-real-name":
                    this.$router.push("/profiles/real-name");
                    break;
                case "finance-bank-card":
                    this.$router.push("/profiles/bank-card");
                    break;
                case "finance-balance":
                    this.$router.push("/profiles/balance");
                    break;
                case "finance-income":
                    this.$router.push("/profiles/income");
                    break;
                case "finance-expense":
                    this.$router.push("/profiles/expense");
                    break;
                case "finance-withdraw":
                    this.$router.push("/profiles/withdraw");
                    break;
                case "finance-refund":
                    this.$router.push("/profiles/refund");
                    break;
                case "chat-list":
                    this.$router.push("/profiles/chat");
                    break;
                default:
                    break;
            }
        },
    }
}

