

import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'AdminAside',
    data() {
        return {
            defaultOpened: ["audit", "operation", "feedback"],
        };
    },
    computed: {
        ...mapGetters({
            activeIndex: 'admin/activeIndex'
        }),
    },
    methods: {
        ...mapMutations({
            setActiveIndex: 'admin/setActiveIndex'
        }),
        handleOpen(key, keyPath) {
            console.log("==handleOpen", key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log("==handleClose", key, keyPath);
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.setActiveIndex(key)
            switch (key) {
                case "subject-company":
                    this.$router.push("/admins/sub-company");
                    break;
                case "subject-user":
                    this.$router.push("/admins/sub-user");
                    break;
                case "subject-jd":
                    this.$router.push("/admins/sub-jd");
                    break;
                case "audit-company":
                    this.$router.push("/admins/audit-company");
                    break;
                case "audit-referee":
                    this.$router.push("/admins/audit-referee");
                    break;
                case "audit-user":
                    this.$router.push("/admins/audit-user");
                    break;
                case "audit-jd":
                    this.$router.push("/admins/audit-jd");
                    break;
                case "audit-order":
                    this.$router.push("/admins/audit-order");
                    break;
                case "audit-withdraw":
                    this.$router.push("/admins/audit-withdraw");
                    break;
                case "audit-real-name":
                    this.$router.push("/admins/audit-real-name");
                    break;
                case "operation-carousel":
                    this.$router.push("/admins/op-carousel");
                    break;
                case "operation-hot":
                    this.$router.push("/admins/op-hot");
                    break;
                case "operation-home-latest":
                    this.$router.push("/admins/op-home-latest");
                    break;
                case "operation-home-hot":
                    this.$router.push("/admins/op-home-hot");
                    break;
                case "operation-sensitive-word":
                    this.$router.push("/admins/op-sensitive");
                    break;
                case "operation-jd-time":
                    this.$router.push("/admins/op-jd-time");
                    break;
                case "chat-list":
                    this.$router.push("/admins/chat");
                    break;
                default:
                    break;
            }
        },
    }
}

