import {getIndustryName, getScaleName, getStageName} from "@/utils/job";
import {
    STATUS_APPLYING, STATUS_APPROVED,
    STATUS_APPROVED_WITH_APPLYING,
    STATUS_APPROVED_WITH_REJECTED,
    STATUS_NEW, STATUS_OFFLINE_BY_ADMIN, STATUS_OFFLINE_BY_OWNER, STATUS_REJECTED
} from "@/constants/audit";
import {getCompanyStatusName} from "@/utils/company";

export default class Company {

    constructor(options) {
        this._id = options?._id || 0
        this.fullName = options?.fullName || ""
        this.nickName = options?.nickName || ""
        this.logoKey = options?.logoKey || ""
        this.logoUrl = options?.logoUrl || ""

        this.industryId = options?.industryId || null
        this.industryId2 = options?.industryId2 || null
        this.industryId3 = options?.industryId3 || null

        this.industryIds = []
        if (this.industryId) {
            this.industryIds.push(this.industryId)
        }
        if (this.industryId2) {
            this.industryIds.push(this.industryId2)
        }
        if (this.industryId3) {
            this.industryIds.push(this.industryId3)
        }

        this.scaleId = options?.scaleId || null
        this.stageId = options?.stageId || null
        this.webSiteUrl = options?.webSiteUrl || ""
        this.statusId = options?.statusId || 0
        this.statusText = options?.statusText || "-"
        this.auditExtra = options?.auditExtra || "{}"
    }

    get industryName() {
        return getIndustryName(this.industryId)
    }

    get industryName2() {
        return getIndustryName(this.industryId2)
    }

    get industryName3() {
        return getIndustryName(this.industryId3)
    }

    get industryNames() {
        return [this.industryName,
            this.industryName2,
            this.industryName3]
            .filter(function (s) {
                return s && s.trim()
            }).join(",")
    }

    get shortIndustryNames() {
        let names = this.industryName
        if (this.industryIds.length>1) {
            names += "等"
        }
        return names
    }

    get scaleName() {
        return getScaleName(this.scaleId)
    }

    get stageName() {
        return getStageName(this.stageId)
    }

    get isNew() {
        return this._id <= 0
    }

    get auditApplicant() {
        let audit = JSON.parse(this.auditExtra)
        return audit?.applicant
    }

    get auditCompany() {
        let audit = JSON.parse(this.auditExtra)
        let company = audit?.company
        if (company) {
            company.statusText = this.statusText
            switch (this.statusId) {
                case STATUS_APPROVED:
                    company.statusId = STATUS_APPROVED
                    break
                case STATUS_APPROVED_WITH_APPLYING:
                    company.statusId = STATUS_APPLYING
                    break
                case STATUS_APPROVED_WITH_REJECTED:
                    company.statusId = STATUS_REJECTED
                    break
                case STATUS_REJECTED:
                    company.statusId = STATUS_REJECTED
                    break
                default:
                    company.statusId = STATUS_NEW
                    break
            }
        }
        return new Company(company)
    }

    get hasAuditCompany() {
        let audit = JSON.parse(this.auditExtra)
        return !!audit?.company
    }

    get statusShort() {
        //return companyStatusShort(this.statusId)
        return getCompanyStatusName(this.statusId)
    }

    get canApply() {
        return this.statusId == STATUS_APPROVED
            || this.statusId == STATUS_APPROVED_WITH_REJECTED
            || this.statusId == STATUS_REJECTED
    }

    get applyUpdateStatus() {
        if (this.statusId == STATUS_APPROVED
            || this.statusId == STATUS_APPROVED_WITH_REJECTED) {
            return STATUS_APPROVED_WITH_APPLYING
        }
        return STATUS_APPLYING
    }

    get isApproved() {
        return this.statusId == STATUS_APPROVED
            || this.statusId == STATUS_APPROVED_WITH_APPLYING
            || this.statusId == STATUS_APPROVED_WITH_REJECTED
    }

    get isAuditApplying() {
        return this.statusId == STATUS_APPLYING
            || this.statusId == STATUS_APPROVED_WITH_APPLYING
    }

    get isAuditApproved() {
        return this.statusId == STATUS_APPROVED
    }

    get isAuditRejected() {
        return this.statusId == STATUS_REJECTED
            || this.statusId == STATUS_APPROVED_WITH_REJECTED
    }

    get isAuditOfflineByAdmin() {
        return this.statusId == STATUS_OFFLINE_BY_ADMIN
    }

    get isAuditNothing() {
        return this.statusId == STATUS_NEW
            || this.statusId == STATUS_OFFLINE_BY_OWNER
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = this.statusId
        vo.statusText = this.statusText
        vo.auditExtra = this.auditExtra
        return vo
    }

    toCreateData() {
        let vo = this.commonData()
        vo.statusId = STATUS_APPLYING
        return vo
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = this.applyUpdateStatus
        return vo
    }

    get getIndustryId() {
        return this.industryIds?.length > 0 ? this.industryIds[0] : this.industryId
    }

    get getIndustryId2() {
        return this.industryIds?.length > 1 ? this.industryIds[1] : this.industryId2
    }

    get getIndustryId3() {
        return this.industryIds?.length > 2 ? this.industryIds[2] : this.industryId3
    }

    commonData() {
        return {
            fullName: this.fullName,
            nickName: this.nickName,
            logoKey: this.logoKey,
            logoUrl: this.logoUrl,

            industryId: this.getIndustryId,
            industryId2: this.getIndustryId2,
            industryId3: this.getIndustryId3,

            scaleId: this.scaleId,
            stageId: this.stageId,
            webSiteUrl: this.webSiteUrl,

        }

    }

    clone() {
        let options = this.toData()
        return new Company(options)
    }

    toJSON () {
        return { ...this }
    }
}
