export const state = () => ({
    activeIndex: "",
})

export const mutations = {
    setActiveIndex(state, payload) {
        state.activeIndex = payload
    },
}

export const getters = {
    activeIndex(state) {
        return state.activeIndex
    },
}
