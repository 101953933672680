export default class CvoSocial {

    constructor(options) {
        this._id = options?._id || 0
        this.cvId = options?.cvId || 0

        this.name = options?.name || ""
        this.url = options?.url || ""

        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""

        //新建默认就是编辑状态
        this.isEditing = this.isNew
    }

    get isNew() {
        return this._id <= 0
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            cvId: this.cvId,
            name: this.name,
            url: this.url,
        }
    }

    clone() {
        let options = this.toData()
        return new CvoSocial(options)
    }

    toJSON() {
        return {...this}
    }
}
