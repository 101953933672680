var validatePass = ruleForm => (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请再次输入密码'));
    } else if (value !== ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
    } else {
        callback();
    }
};

var rules = ruleForm => {
    return {
        mobile: [
            {required: true, type: 'string', message: '请输入手机号码', trigger: 'blur'},
            {pattern: /^1[3-9]\d{9}$/, message: '请确保手机号码格式正确', trigger: 'blur'},
        ],
        "captcha.captchaCode": [
            {required: true, type: 'string', message: '请输入图片验证码', trigger: 'blur'},
            {len: 5, message: '验证码长度为5', trigger: 'blur'}
        ],
        "smsCode.value": [
            {type: 'string', required: true, message: '请输入短信验证码', trigger: 'blur'},
            {len: 6, message: '短信验证码长度为5', trigger: 'blur'}
        ],
        password: [
            {type: 'string', required: true, message: '请输入密码', trigger: 'blur'},
            {min: 6, message: '密码长度不小于6', trigger: 'blur'}
        ],
        repassword: [
            {validator: validatePass(ruleForm), trigger: 'blur'}
        ],
        introCode: [
            {type: 'string', required: false, message: '请输入邀请码(选填)', trigger: 'blur'},
            {len: 8, message: '邀请码长度等于8', trigger: 'blur'}
        ],
        agreements: [
            { type: 'array', required: true, message: '注册需同意用户协议和隐私政策', trigger: 'change' }
        ],
    }
};

export default rules;
