

import {provinces} from "@/utils/area";

export default {
    name: "CityDialog",
    props: {
        isVisible: Boolean,
        city: Object,
    },
    data() {
        return {
            tabPosition: 'left',
            provinces: provinces()
        }
    },
    methods: {
        onClose() {
            this.$emit('update:isVisible', false);
        },
        onCityClicked(province,city) {
            this.$emit('update:city', {
                provinceId: province._id,
                provinceName: province.name,
                cityId: city._id,
                cityName: city.name,
            });
            this.onClose()
        },
        resetForm() {

        }
    }

}
