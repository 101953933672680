import jsMd5 from 'js-md5';

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function md5(str) {
    return jsMd5(str)
}

function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function isFloat(value) {
    return value===+value && value!==(value|0);
}

function isInt(value) {
    return typeof value === 'number'
        && parseFloat(value) == parseInt(value, 10)
        && !isNaN(value);
}

function isBool(value){
    return value===!!value;
}

function isString(value){
    return value===value+'';
}

function isUndefined(value) {
    return typeof(value) == "undefined"
}

function isNull(value) {
    return !value && typeof(value)!="undefined" && value!=0
}

function isNullOrUndefined(value) {
    return value == undefined
}

function toInt(value) {
    let ret = 0
    try {
        ret = parseInt(value, 10)
    } catch (e) {
        console.log(e)
    }
    return ret
}

function toFloat(value) {
    let ret = 0
    try {
        ret = parseFloat(value)
    } catch (e) {
        console.log(e)
    }
    return ret
}

function isFunction(value) {
    return Object.prototype.toString.call(value) === '[object Function]'
}

function deepEqual(obj1, obj2) {

    if(obj1 === obj2) { // it's just the same object. No need to compare.
        return true;
    }

    if(isPrimitive(obj1) && isPrimitive(obj2)) { // compare primitives
        return obj1 === obj2;
    }

    if(Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }

    // compare objects with same number of keys
    for(let key in obj1) {
        if(!(key in obj2)) {
            return false;
        }
        //other object doesn't have this prop
        if(!deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

//check if value is primitive
function isPrimitive(obj) {
    return (obj !== Object(obj));
}

function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export default {
    uuid,
    md5,
    isNumber,
    isFloat,
    isInt,
    isBool,
    isString,
    isUndefined,
    isNull,
    isNullOrUndefined,
    toInt,
    toFloat,
    isFunction,
    deepEqual,
    isPrimitive,
    deepClone,
}
