import moment from "moment";

export default class CvoEdu {

    constructor(options) {
        this._id = options?._id || 0
        this.cvId = options?.cvId || 0

        this.school = options?.school || ""
        this.degreeId = options?.degreeId || ""
        this.major = options?.major || ""
        this.from = options?.from || ""
        this.to = options?.to || ""
        this.logoKey = options?.logoKey || ""
        this.logoUrl = options?.logoUrl || ""

        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""

        //新建默认就是编辑状态
        this.isEditing = this.isNew
    }

    get isNew() {
        return this._id <= 0
    }

    get dateRange() {
        return [
            moment(this.from).toDate(),
            moment(this.to).toDate()
        ]
    }

    set dateRange(newValue) {
        this.from = moment(newValue[0]).format("YYYY-MM-DD")
        this.to = moment(newValue[1]).format("YYYY-MM-DD")
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            cvId: this.cvId,
            school: this.school,
            degreeId: this.degreeId,
            major: this.major,
            from: this.from,
            to: this.to,
            logoKey: this.logoKey,
            logoUrl: this.logoUrl,
        }
    }

    clone() {
        let options = this.toData()
        return new CvoEdu(options)
    }

    toJSON() {
        return {...this}
    }
}
