import * as rpc from "@/constants/rpc"
import Referee from "~/models/referee";
import {STATUS_APPLYING} from "@/constants/audit";

export const state = () => ({
    referee: new Referee(),
})

export const mutations = {
    setReferee(state, referee) {
        state.referee = referee
    },
}

export const getters = {
    // isLogin (state, getters) {
    //     return state.accessToken && state.user && state.user._id > 0
    // },
    // isLoginUser(state, getters) {
    //     return getters.isLogin && state.user.roleId === 1
    // },
}

export const actions = {

    async SendCompanyEmailAuth(context, to) {
        return await this.$axios.rpcMsg(rpc.USER, rpc.EMAIL_SEND_AUTH, {to})
    },

    async VerifyCompanyEmail(context, refereeId, authCode) {
        return await this.$axios.rpcMsg(rpc.USER, rpc.EMAIL_VERIFY, {refereeId, authCode})
    },

    async getReferee(context, _id) {
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REFEREE_GET, {_id})
        if (ret && ret.result) {
            context.commit('setReferee', new Referee(ret.result))
        }
    },

    async applyNewReferee(context, referee) {
        const params = referee.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REFEREE_APPLY_NEW, params)
        if (ret && ret.result) {
            referee._id = ret.result._id
            referee.statusId = STATUS_APPLYING
            context.commit('user/setUserRefereeId', referee._id, {root: true})
            context.commit('setReferee', referee)
        }
        return ret
    },
    async applyUpdateReferee(context, referee) {
        const params = referee.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.USER, rpc.REFEREE_APPLY_UPDATE, params)
        if (ret && ret.result) {
            context.commit('setReferee', referee)
        }
        return ret
    },
}
