import { render, staticRenderFns } from "./profile.vue?vue&type=template&id=06f68ba3&scoped=true"
import script from "./profile.vue?vue&type=script&lang=js"
export * from "./profile.vue?vue&type=script&lang=js"
import style0 from "./profile.vue?vue&type=style&index=0&id=06f68ba3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f68ba3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmnHeader: require('/ntweb/components/cmn-header.vue').default,ProfileAside: require('/ntweb/components/profile-aside.vue').default,CmnFooter: require('/ntweb/components/cmn-footer.vue').default})
