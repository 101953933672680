import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=533dcdde&scoped=true"
import script from "./admin.vue?vue&type=script&lang=js"
export * from "./admin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533dcdde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmnHeader: require('/ntweb/components/cmn-header.vue').default,AdminAside: require('/ntweb/components/admin-aside.vue').default,CmnFooter: require('/ntweb/components/cmn-footer.vue').default})
