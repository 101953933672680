import * as rpc from "@/constants/rpc"
import Cvo from "~/models/cvo";
import Vue from "vue";
import {CV_ONLINE} from "@/constants/cv";
import Cv from "@/models/cv";
import CvoIntent from "@/models/cvo-intent";
import CvoWorkExp from "@/models/cvo-work-exp";
import CvoSocial from "@/models/cvo-social";
import CvoEdu from "@/models/cvo-edu";
import CvAttachment from "@/models/cv-attachment";
import CvoProjectExp from "@/models/cvo-project-exp";
import moment from "moment";

export const state = () => ({
    cvo: new Cvo(),
})

export const mutations = {
    resetCvo(state) {
        state.cvo = new Cvo()
    },
    setCvo(state, cvo) {
        state.cvo = cvo
    },
    setCv(state, cv) {
        state.cvo.cv = cv
    },
    setBasic(state, basic) {
        state.cvo.basic = basic
    },
    setAdvantage(state, adv) {
        state.cvo.advantage = adv
    },

    setNameEdit(state, isEdit) {
        state.cvo.isNameEditing = isEdit
    },
    setBasicEdit(state, isEdit) {
        state.cvo.isBasicEditing = isEdit
    },
    setAdvEdit(state, isEdit) {
        state.cvo.isAdvEditing = isEdit
    },
    setAttachEdit(state, isEdit) {
        state.cvo.isAttachEditing = isEdit
    },


    // intent without actions
    createIntentDraft(state) {
        let intent = new CvoIntent()
        intent.cvId = state.cvo.cv._id
        state.cvo.intents.push(intent)
    },
    deleteIntentDraft(state) {
        const index = state.cvo.intents.findIndex(obj => obj._id === 0)
        state.cvo.intents.splice(index,1)
    },

    // intent for actions
    createIntent(state, intent) {
        // find draft first
        const index = state.cvo.intents.findIndex(obj => obj._id === 0)
        // replace then
        Vue.set(state.cvo.intents,index,intent)
    },
    updateIntent(state, intent) {
        const index = state.cvo.intents.findIndex(obj => obj._id === intent._id)
        Vue.set(state.cvo.intents,index,intent)
    },
    deleteIntent(state, intent) {
        const index = state.cvo.intents.findIndex(obj => obj._id === intent._id)
        state.cvo.intents.splice(index,1)
    },

    // work exp without actions
    createWorkExpDraft(state) {
        let workExp = new CvoWorkExp()
        workExp.cvId = state.cvo.cv._id
        state.cvo.workExps.push(workExp)
    },
    deleteWorkExpDraft(state) {
        const index = state.cvo.workExps.findIndex(obj => obj._id === 0)
        state.cvo.workExps.splice(index,1)
    },

    // work exp for actions
    createWorkExp(state, workExp) {
        // find draft first
        const index = state.cvo.workExps.findIndex(obj => obj._id === 0)
        // replace then
        Vue.set(state.cvo.workExps,index,workExp)
        // sort
        state.cvo.workExps.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    updateWorkExp(state, workExp) {
        const index = state.cvo.workExps.findIndex(obj => obj._id === workExp._id)
        Vue.set(state.cvo.workExps,index,workExp)
        // sort
        state.cvo.workExps.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    deleteWorkExp(state, workExp) {
        const index = state.cvo.workExps.findIndex(obj => obj._id === workExp._id)
        state.cvo.workExps.splice(index,1)
    },

    // project exp without actions
    createProjectExpDraft(state) {
        let projectExp = new CvoProjectExp()
        projectExp.cvId = state.cvo.cv._id
        state.cvo.projectExps.push(projectExp)
    },
    deleteProjectExpDraft(state) {
        const index = state.cvo.projectExps.findIndex(obj => obj._id === 0)
        state.cvo.projectExps.splice(index,1)
    },

    // project exp for actions
    createProjectExp(state, projectExp) {
        // find draft first
        const index = state.cvo.projectExps.findIndex(obj => obj._id === 0)
        // replace then
        Vue.set(state.cvo.projectExps,index,projectExp)
        // sort
        state.cvo.projectExps.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    updateProjectExp(state, projectExp) {
        const index = state.cvo.projectExps.findIndex(obj => obj._id === projectExp._id)
        Vue.set(state.cvo.projectExps,index,projectExp)
        // sort
        state.cvo.projectExps.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    deleteProjectExp(state, projectExp) {
        const index = state.cvo.projectExps.findIndex(obj => obj._id === projectExp._id)
        state.cvo.projectExps.splice(index,1)
    },

    // social without actions
    createSocialDraft(state) {
        let social = new CvoSocial()
        social.cvId = state.cvo.cv._id
        state.cvo.socials.push(social)
    },
    deleteSocialDraft(state) {
        const index = state.cvo.projectExps.findIndex(obj => obj._id === 0)
        state.cvo.projectExps.splice(index,1)
    },

    // social for actions
    createSocial(state, social) {
        // find draft first
        const index = state.cvo.socials.findIndex(obj => obj._id === 0)
        // replace then
        Vue.set(state.cvo.socials,index,social)
    },
    updateSocial(state, social) {
        const index = state.cvo.socials.findIndex(obj => obj._id === social._id)
        Vue.set(state.cvo.socials,index,social)
    },
    deleteSocial(state, social) {
        const index = state.cvo.socials.findIndex(obj => obj._id === social._id)
        state.cvo.socials.splice(index,1)
    },


    // education without actions
    createEduDraft(state) {
        let edu = new CvoEdu()
        edu.cvId = state.cvo.cv._id
        state.cvo.educations.push(edu)
    },
    deleteEduDraft(state) {
        const index = state.cvo.educations.findIndex(obj => obj._id === 0)
        state.cvo.educations.splice(index,1)
    },

    // education for actions
    createEdu(state, edu) {
        // find draft first
        const index = state.cvo.educations.findIndex(obj => obj._id === 0)
        // replace then
        Vue.set(state.cvo.educations,index,edu)
        // sort
        state.cvo.educations.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    updateEdu(state, edu) {
        const index = state.cvo.educations.findIndex(obj => obj._id === edu._id)
        Vue.set(state.cvo.educations,index,edu)
        // sort
        state.cvo.educations.sort(function (a,b) {
            return moment(a.from).isBefore(moment(b.from))
        })
    },
    deleteEdu(state, edu) {
        const index = state.cvo.educations.findIndex(obj => obj._id === edu._id)
        state.cvo.educations.splice(index,1)
    },
    updateAttachments(state, files) {
        state.cvo.attachments = files
    },
}

export const getters = {
    isNew (state, getters) {
        return state.cvo?.cv?._id <= 0
    },
    hasIntentDraft(state, getters) {
        const index = state.cvo.intents.findIndex(obj => obj._id === 0)
        return index >= 0
    },
    hasWorkExpDraft(state, getters) {
        const index = state.cvo.workExps.findIndex(obj => obj._id === 0)
        return index >= 0
    },
    hasProjectExpDraft(state, getters) {
        const index = state.cvo.projectExps.findIndex(obj => obj._id === 0)
        return index >= 0
    },
    hasSocialDraft(state, getters) {
        const index = state.cvo.socials.findIndex(obj => obj._id === 0)
        return index >= 0
    },
    hasEduDraft(state, getters) {
        const index = state.cvo.educations.findIndex(obj => obj._id === 0)
        return index >= 0
    },
    isNameEditing(state, getters) {
        return state.cvo.isNameEditing
    },
    isBasicEditing(state, getters) {
        return state.cvo.isBasicEditing
    },
    isAdvEditing(state, getters) {
        return state.cvo.isAdvEditing
    },
    isAttachEditing(state, getters) {
        return state.cvo.isAttachEditing
    },
}

export const actions = {

    async getCvo(context, cvId) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_GET, {_id:cvId})
        if (ret && ret.result) {
            let cvo = new Cvo(ret.result)
            context.commit('setCvo', cvo)
        }
        return ret
    },
    async deleteCvo(context,cvId) {
        return await this.$axios.rpcMsg(rpc.CONTENT, rpc.CV_DELETE, {_id: cvId})
    },
    async cloneCvo(context,cvId) {
        return await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_CLONE, {_id: cvId})
    },
    async createCv(context, name) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CV_CREATE, {name, typeId:CV_ONLINE})
        if (ret && ret.result) {
            let cv = new Cv({
                _id: ret.result._id,
                name: name,
                typeId: CV_ONLINE
            })
            context.commit('setCv', cv)
        }
        return ret
    },
    async updateCv(context,{_id,name}) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CV_UPDATE, {_id,name,typeId:CV_ONLINE})
        if (ret && ret.result) {
            let cv = new Cv({_id,name,typeId: CV_ONLINE})
            context.commit('setCv', cv)
        }
        return ret
    },
    async createBasic(context, basic) {
        let params = basic.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_BASIC_CREATE, params)
        if (ret && ret.result) {
            basic._id = ret.result._id
            context.commit('setBasic', basic)
        }
        return ret
    },
    async updateBasic(context, basic) {
        let params = basic.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_BASIC_UPDATE, params)
        if (ret && ret.result) {
            context.commit('setBasic', basic)
        }
        return ret
    },
    async createAdvantage(context, adv) {
        let params = adv.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_ADV_CREATE, params)
        if (ret && ret.result) {
            adv._id = ret.result._id
            context.commit('setAdvantage', adv)
        }
        return ret
    },
    async updateAdvantage(context, adv) {
        let params = adv.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_ADV_UPDATE, params)
        if (ret && ret.result) {
            context.commit('setAdvantage', adv)
        }
        return ret
    },
    async createIntent(context, intent) {
        let params = intent.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_INTENT_CREATE, params)
        if (ret && ret.result) {
            intent._id = ret.result._id
            intent.isEditing = false
            context.commit('createIntent', intent)
        }
        return ret
    },
    async updateIntent(context, intent) {
        let params = intent.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_INTENT_UPDATE, params)
        if (ret && ret.result) {
            intent.isEditing = false
            context.commit('updateIntent', intent)
        }
        return ret
    },
    async deleteIntent(context, intent) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_INTENT_DELETE, {_id: intent._id})
        if (ret && ret.result) {
            context.commit('deleteIntent', intent)
        }
        return ret
    },
    async createWorkExp(context, workExp) {
        let params = workExp.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_WORK_EXP_CREATE, params)
        if (ret && ret.result) {
            workExp._id = ret.result._id
            workExp.isEditing = false
            context.commit('createWorkExp', workExp)
        }
        return ret
    },
    async updateWorkExp(context, workExp) {
        let params = workExp.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_WORK_EXP_UPDATE, params)
        if (ret && ret.result) {
            workExp.isEditing = false
            context.commit('updateWorkExp', workExp)
        }
        return ret
    },
    async deleteWorkExp(context, workExp) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_WORK_EXP_DELETE, {_id: workExp._id})
        if (ret && ret.result) {
            context.commit('deleteWorkExp', workExp)
        }
        return ret
    },
    async createProjectExp(context, projectExp) {
        let params = projectExp.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_PROJECT_EXP_CREATE, params)
        if (ret && ret.result) {
            projectExp._id = ret.result._id
            projectExp.isEditing = false
            context.commit('createProjectExp', projectExp)
        }
        return ret
    },
    async updateProjectExp(context, projectExp) {
        let params = projectExp.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_PROJECT_EXP_UPDATE, params)
        if (ret && ret.result) {
            projectExp.isEditing = false
            context.commit('updateProjectExp', projectExp)
        }
        return ret
    },
    async deleteProjectExp(context, projectExp) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_PROJECT_EXP_DELETE, {_id: projectExp._id})
        if (ret && ret.result) {
            context.commit('deleteProjectExp', projectExp)
        }
        return ret
    },
    async createSocial(context, social) {
        let params = social.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_SOCIAL_CREATE, params)
        if (ret && ret.result) {
            social._id = ret.result._id
            social.isEditing = false
            context.commit('createSocial', social)
        }
        return ret
    },
    async updateSocial(context, social) {
        let params = social.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_SOCIAL_UPDATE, params)
        if (ret && ret.result) {
            social.isEditing = false
            context.commit('updateSocial', social)
        }
        return ret
    },
    async deleteSocial(context, social) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_SOCIAL_DELETE, {_id: social._id})
        if (ret && ret.result) {
            context.commit('deleteSocial', social)
        }
        return ret
    },
    async createEdu(context, edu) {
        let params = edu.toCreateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_EDU_CREATE, params)
        if (ret && ret.result) {
            edu._id = ret.result._id
            edu.isEditing = false
            context.commit('createEdu', edu)
        }
        return ret
    },
    async updateEdu(context, edu) {
        let params = edu.toUpdateData()
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_EDU_UPDATE, params)
        if (ret && ret.result) {
            edu.isEditing = false
            context.commit('updateEdu', edu)
        }
        return ret
    },
    async deleteEdu(context, edu) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_EDU_DELETE, {_id: edu._id})
        if (ret && ret.result) {
            context.commit('deleteEdu', edu)
        }
        return ret
    },
    async updateAttachments(context, { cvId, files }) {
        const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.CVO_ATTACHMENT_UPDATE, { cvId,files })
        if (ret && ret.result) {
            let arr = []
            for (let i=0;i<files.length;i++) {
                let f = files[i]
                let at = new CvAttachment(f)
                at.cvId = cvId
                arr.push(at)
            }
            context.commit('updateAttachments', arr)
            context.commit('setAttachEdit',false)
        }
        return ret
    },
}
