
import rules from "@/utils/account";
import * as err from "@/constants/err";

export default {
    name: "ResetDialog",
    props: {
        isVisible: Boolean,
    },
    data() {
        return {
            form: {
                mobile: '',
                captcha:{
                    captchaId: "",
                    captchaCode: "",
                    captchaB64Image: "",
                },
                smsCode: {
                    value: "",
                    text: "获取验证码",
                    disable: false,
                    counter: 60,
                },
                password: '',
                repassword:''
            },
        }
    },
    computed: {
        formRules() {
            return rules(this.form)
        },
    },
    methods: {
        async getCaptcha() {
            const ret = await this.$store.dispatch('user/getCaptcha')
            if (ret && ret.result) {
                this.form.captcha.captchaId = ret.result.captchaId
                this.form.captcha.captchaB64Image = ret.result.captchaB64Image
            }
        },
        captchaClicked() {
            this.getCaptcha()
        },
        onOpen() {
            this.$nextTick(() => {
                this.$refs['form'].resetFields();
            })
            this.getCaptcha()
        },
        smsCodeClicked() {
            this.$nextTick(() => {
                this.$refs['form'].clearValidate(['smsCode.value','password','repassword']);

                let fields = ["mobile","captcha.captchaCode"]
                let okCounter = 0
                this.$refs['form'].validateField(fields,(errorMsg) => {
                    if (!errorMsg) {
                        okCounter++
                    } else {
                        console.log('validate failed!!');
                        return false;
                    }
                });

                if (okCounter===fields.length) {
                    this.getSmsCode()
                }
            })
        },
        setSmsCodeCounter() {
            let interval = setInterval(()=> {
                if (this.form.smsCode.counter<1) {
                    this.form.smsCode.disable = false
                    this.form.smsCode.text = "获取验证码"
                    this.form.smsCode.counter = 60
                    clearInterval(interval);
                } else {
                    this.form.smsCode.disable = true
                    this.form.smsCode.text = this.form.smsCode.counter-- + "秒后可重发";
                }
            },1000)
        },
        async getSmsCode() {
            const params = {
                mobile: this.form.mobile,
                captchaId: this.form.captcha.captchaId,
                captchaCode: this.form.captcha.captchaCode,
            }
            const ret = await this.$store.dispatch('user/getResetSmsCode',params)
            if (ret && ret.result) {
                this.setSmsCodeCounter()
                this.$message({
                    type: 'success',
                    message: '短信验证码已发出!'
                });
            }
            if (ret && ret.error) {
                await this.getCaptcha()
                this.$message({
                    type: 'error',
                    message: ret.error.message,
                });
            }
        },

        onClose() {
            this.$emit('update:isVisible', false);
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.reset()
                } else {
                    console.log('validate failed!!');
                    return false;
                }
            });
        },
        async reset() {
            const params = {
                mobile: this.form.mobile,
                password: this.form.password,
                smsCode: this.form.smsCode.value,
            }
            const ret = await this.$store.dispatch('user/resetPassword',params)
            if (ret && ret.result) {
                this.onClose()
                this.$message({
                    type: 'success',
                    message: '重置密码成功,请使用新密码登录!'
                });
            }
            if (ret && ret.error) {
                await this.getCaptcha()
                this.$message({
                    type: 'error',
                    message: ret.error.message,
                });
            }
        }
    }

}
