

export default {
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    computed: {
        desc: function () {
            let msg = ""
            switch (this.error.statusCode) {
                case 404:
                    msg = "没有找到该页面，请检查网址是否正确"
                    break;
                case 403:
                    msg = "没有权限访问该页面"
                    break;
                default:
                    msg = "服务器开小差了，稍后请重试"
                    break;
            }
            return this.error.statusCode + " " + msg
        }
    },
    methods: {
        goHome() {
            this.$router.push("/");
        }
    }

}

