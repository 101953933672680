import {cityName, cityName2, provinceName} from "@/utils/area";
import {fen2Yuan, yuan2Fen} from "@/utils/money";

export default class CvoIntent {

    constructor(options) {
        this._id = options?._id || 0
        this.cvId = options?.cvId || 0

        this.jobTitle = options?.jobTitle || ""
        this.provinceId = options?.provinceId || null
        this.cityId = options?.cityId || null
        this.salaryFrom = options?.salaryFrom || 0
        this.salaryTo = options?.salaryTo || 0

        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""

        //新建intent默认就是编辑状态
        this.isEditing = this.isNew
    }

    get isNew() {
        return this._id <= 0
    }

    get provinceName() {
        return provinceName(this.provinceId)
    }

    get cityName() {
        return cityName2(this.provinceId, this.cityId)
    }

    get salaryFromYuan() {
        return fen2Yuan(this.salaryFrom)
    }

    set salaryFromYuan(newValue) {
        let val = newValue || 0
        this.salaryFrom = yuan2Fen(val)
    }

    get salaryToYuan() {
        return fen2Yuan(this.salaryTo)
    }

    set salaryToYuan(newValue) {
        let val = newValue || 0
        this.salaryTo = yuan2Fen(val)
    }

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.createdAt = this.createdAt
        vo.updatedAt = this.updatedAt
        return vo
    }

    toCreateData() {
        return this.commonData()
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            cvId: this.cvId,
            jobTitle: this.jobTitle,
            provinceId: this.provinceId,
            cityId: this.cityId,
            salaryFrom: this.salaryFrom,
            salaryTo: this.salaryTo,
        }
    }

    clone() {
        let options = this.toData()
        return new CvoIntent(options)
    }

    toJSON() {
        return {...this}
    }
}
